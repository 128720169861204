export const BENEFICIARIES_FETCH = 'beneficiaries/FETCH';
export const BENEFICIARIES_DATA  = 'beneficiaries/DATA';
export const BENEFICIARIES_ERROR = 'beneficiaries/ERROR';

export const BENEFICIARIES_ACTIVATE       = 'beneficiaries/ACTIVATE';
export const BENEFICIARIES_ACTIVATE_DATA  = 'beneficiaries/ACTIVATE_DATA';
export const BENEFICIARIES_ACTIVATE_ERROR = 'beneficiaries/ACTIVATE_ERROR';

export const BENEFICIARIES_CREATE       = 'beneficiaries/CREATE';
export const BENEFICIARIES_CREATE_DATA  = 'beneficiaries/CREATE_DATA';
export const BENEFICIARIES_CREATE_ERROR = 'beneficiaries/CREATE_ERROR';

export const BENEFICIARIES_DELETE       = 'beneficiaries/DELETE';
export const BENEFICIARIES_DELETE_DATA  = 'beneficiaries/DELETE_DATA';
export const BENEFICIARIES_DELETE_ERROR = 'beneficiaries/DELETE_ERROR';
