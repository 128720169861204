export const P2P_USER_OFFERS_FETCH = 'p2p_user_offers/FETCH';
export const P2P_USER_OFFERS_DATA  = 'p2p_user_offers/DATA';
export const P2P_USER_OFFERS_ERROR = 'p2p_user_offers/ERROR';

export const P2P_USER_OFFER_ORDERS_FETCH = 'p2p_user_offer_orders/FETCH';
export const P2P_USER_OFFER_ORDERS_DATA  = 'p2p_user_offer_orders/DATA';
export const P2P_USER_OFFER_ORDERS_ERROR = 'p2p_user_offer_orders/ERROR';

export const P2P_USER_OFFERS_UPDATE = 'p2p_user_offers/UPDATE';

export const P2P_CREATE_OFFER_FETCH = 'p2p_create_offer/FETCH';
export const P2P_CREATE_OFFER_DATA  = 'p2p_create_offer/DATA';
export const P2P_CREATE_OFFER_ERROR = 'p2p_create_offer/ERROR';

export const P2P_UPDATE_OFFER_FETCH = 'p2p_update_offer/FETCH';
export const P2P_UPDATE_OFFER_DATA  = 'p2p_update_offer/DATA';
export const P2P_UPDATE_OFFER_ERROR = 'p2p_update_offer/ERROR';

export const P2P_USER_OFFER_DETAIL_FETCH = 'p2p_user_offer_detail/FETCH';
export const P2P_USER_OFFER_DETAIL_DATA  = 'p2p_user_offer_detail/DATA';
export const P2P_USER_OFFER_DETAIL_ERROR = 'p2p_user_offer_detail/ERROR';
