import * as React from 'react';

interface LetterIconProps {
    className?: string;
}

export const LetterIcon: React.FC<LetterIconProps> = (props: LetterIconProps) => (
    <svg width="44" height="46" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <path d="M20.3285 19.7122C20.5909 20.0216 21.0505 20.0634 21.3639 19.8067L25.2141 16.6523C25.4237 16.4842 25.5275 16.2158 25.486 15.9495C25.4445 15.6832 25.2643 15.4595 25.0135 15.3646C24.7632 15.2693 24.4809 15.3174 24.2754 15.4901L20.9886 18.1829L19.8071 16.7894C19.6352 16.5829 19.3666 16.4855 19.1034 16.5336C18.8406 16.5821 18.623 16.7685 18.5346 17.0227C18.4462 17.2769 18.4997 17.5591 18.6753 17.7623L20.3285 19.7122Z" fill="var(--primary-cta-color)"/>
        <path d="M21.9929 25.8894C26.5127 25.8894 30.1765 22.1999 30.1765 17.6483C30.1765 13.0972 26.5127 9.40723 21.9929 9.40723C17.4735 9.40723 13.8093 13.0972 13.8093 17.6483C13.8147 22.1978 17.4756 25.8844 21.9929 25.8894ZM21.9929 10.9056C25.6907 10.9056 28.6886 13.9245 28.6886 17.6483C28.6886 21.3725 25.6907 24.391 21.9929 24.391C18.2951 24.391 15.2972 21.3725 15.2972 17.6483C15.3014 13.9262 18.2968 10.9102 21.9929 10.9056Z" fill="var(--primary-cta-color)"/>
        <path d="M42.6296 17.2063L35.6844 12.8901C35.6591 12.8746 35.6333 12.8604 35.6068 12.8483L35.4976 12.7973V10.9741C35.4976 10.7893 35.4299 10.6112 35.3074 10.4736L26.2167 0.267986C26.2051 0.255026 26.1927 0.242902 26.1802 0.230778C26.0403 0.083197 25.8464 0 25.6438 0C25.616 0 25.5878 0.0016723 25.56 0.0050169L25.5471 0.00376267C25.5222 0.00125423 25.4977 0 25.4728 0H12.617C10.3457 0.00250845 8.50491 1.85625 8.50242 4.14355V12.8537L1.37292 17.2046C0.522683 17.7318 0.00332126 18.6646 0 19.6704V40.4935C0.00290602 43.5334 2.44901 45.9967 5.46762 46H38.5324C41.551 45.9967 43.9971 43.5334 44 40.4935V19.6704C43.9967 18.6658 43.4786 17.7339 42.6296 17.2063ZM41.8507 18.4831C42.2601 18.7369 42.5104 19.1859 42.5121 19.6704V20.8084L35.4976 25.1255V14.5348L41.8507 18.4831ZM26.3878 2.70411L32.1506 9.17383H29.0145C27.5643 9.17216 26.3895 7.98858 26.3878 6.52825V2.70411ZM12.617 1.49796H24.8999V6.52825C24.9024 8.81554 26.7432 10.6693 29.0145 10.6722H33.4853L34.0097 11.2609V26.0411L22 33.4318L9.99034 26.0411V4.14355C9.99158 2.68321 11.1669 1.49964 12.617 1.49796ZM2.14678 18.4844L8.50242 14.6055V25.1255L1.48792 20.8084V19.6704C1.48917 19.1872 1.73868 18.7386 2.14678 18.4844ZM38.5324 44.5016H5.46762C3.27061 44.4991 1.49 42.706 1.48792 40.4935V22.5644L8.73366 27.024C8.81046 27.0976 8.90138 27.1536 9.00143 27.1887L21.6118 34.949C21.8501 35.0958 22.1499 35.0958 22.3878 34.949L34.9986 27.1883C35.0986 27.1532 35.1895 27.0971 35.2663 27.0236L42.5121 22.5644V40.4935C42.51 42.706 40.7294 44.4991 38.5324 44.5016Z" fill="var(--primary-cta-color)"/>
    </svg>
);
