import { LangType } from '../../translations';
import { nationalitiesNames } from '../../translations/nationalities';

export const zh: LangType = {
    //General Messages
    'no.record.found': '没有找到记录',
    'page.body.copy': '复制',
    'page.body.copied': '复制的',
    'copied.to.clipboard': '复制到剪贴板.',
    'learn.more': '学到更多',
    'need.to.enable.2fa.title': '使用此功能之前，您需要启用2FA。',
    'need.to.enable.2fa.button.title': '启用2FA',
    'need.to.confirm.account': '您需要先确认您的帐户，然后才能使用此功能。',
    'need.to.confirm.account.button.title': '确认帐号',
    'request.limit.exceeds': '超出请求限制。',
    //End General text

    'page.header.navbar.signIn': '登入',
    'page.header.navbar.trade': '贸易方式',
    'page.header.navbar.wallets': '皮夹',
    'page.header.navbar.swap': '交换',
    'page.header.navbar.buy_sell': '买卖',
    'page.header.navbar.openOrders': '订单',
    'page.header.navbar.history': '历史',

    'page.header.navbar.profile': '个人资料',
    'page.header.navbar.logout': '登出',

    // Header Profile Links
    'page.header.navbar.profile.security': '安全',
    'page.header.navbar.profile.identification': '身份证明',
    'page.header.navbar.profile.api_management': 'API管理',
    'page.header.navbar.profile.referal': '转介',
    'page.header.navbar.profile.activities': '活动项目',

    //Header Notification Tab
    'page.header.navbar.notifications.title': '通知事项',
    'page.header.navbar.notifications.empty.content1': "您是最新的！",
    'page.header.navbar.notifications.empty.content2': '每天访问以获取加密新闻，价格警报等',
    'page.header.navbar.notifications.detail.seen.button.text': '我明白',
    

    'page.sidebar.group.text': '费用组:',
    'page.sidebar.group.value': '基础',

    'page.body.trade.header.market': '市场',
    'page.body.trade.header.yours': '你的',

    'page.body.trade.header.markets': '市场',
    'page.body.trade.header.markets.content.market': '市场',
    'page.body.trade.header.markets.content.pair': '对',
    'page.body.trade.header.markets.content.price': '价钱',
    'page.body.trade.header.markets.content.last_price': '最后价格',
    'page.body.trade.header.markets.content.change': '更改',
    'page.body.trade.header.markets.content.search': '搜索',
    'page.body.trade.header.markets.content.volume': '卷',

    'page.body.trade.header.newOrder': '订单',
    'page.body.trade.header.newOrder.content.tabs.buy': '购买',
    'page.body.trade.header.newOrder.content.tabs.sell': '卖',
    'page.body.trade.header.newOrder.content.orderType': '订单类型',
    'page.body.trade.header.newOrder.content.orderType.limit': '限制',
    'page.body.trade.header.newOrder.content.orderType.market': '市场',
    'page.body.trade.header.newOrder.content.price': '价钱',
    'page.body.trade.header.newOrder.content.amount': '量',
    'page.body.trade.header.newOrder.content.total': '总',
    'page.body.trade.header.newOrder.content.available': '可用的',

    'error.order.create.minAmount': '金额太低。最低金额为 {amount} {currency}',
    'error.order.create.minPrice': '价格太低了。最低价格为 {price} {currency}',
    'error.order.create.maxPrice': '价格太高。最高价格为 {price} {currency}',
    'error.order.create.available': '超出余额。 可用的 {available} {currency}',

    'page.body.trade.header.marketDepths': '市场深度',
    'page.body.trade.header.marketDepths.content.price': '价钱:',
    'page.body.trade.header.marketDepths.content.volume': '量:',
    'page.body.trade.header.marketDepths.content.cumulativeVolume': '总：',
    'page.body.trade.header.marketDepths.content.cumulativeValue': '估计的价值：',

    'page.body.trade.toolBar.lowest': '最低24小时',
    'page.body.trade.toolBar.lastPrice': '最后价格',
    'page.body.trade.toolBar.selectMarket': '选择市场',
    'page.body.trade.toolBar.highest': '最高24小时',
    'page.body.trade.toolBar.volume': '24小时成交量',
    'page.body.trade.toolBar.change': '更改',

    'page.body.trade.header.asks': '问',
    'page.body.trade.header.bids': '竞标',
    'page.body.trade.orderbook': '订购书',
    'page.body.trade.orderbook.lastMarket': '最后市场价',
    'page.body.trade.orderbook.header.price': '价钱',
    'page.body.trade.orderbook.header.amount': '量',
    'page.body.trade.orderbook.header.volume': '总',

    'page.body.trade.header.recentTrades': '最近的交易',
    'page.body.trade.header.recentTrades.content.time': '时间',
    'page.body.trade.header.recentTrades.content.price': '价钱',
    'page.body.trade.header.recentTrades.content.amount': '量',

    'page.body.trade.header.openOrders': '未结订单',
    'page.body.trade.header.openOrders.content.date': '日期',
    'page.body.trade.header.openOrders.content.state': '州',
    'page.body.trade.header.openOrders.content.price': '价钱',
    'page.body.trade.header.openOrders.content.amount': '量',
    'page.body.trade.header.openOrders.content.total': '值',
    'page.body.trade.header.openOrders.content.filled': '填充',


    /* Markets Table */
    'page.body.marketsTable.filter.all': '所有',
    'page.body.marketsTable.header.pair': '市场',
    'page.body.marketsTable.header.lastPrice': '最后价格',
    'page.body.marketsTable.header.change': '24变化',
    'page.body.marketsTable.header.high': '24高',
    'page.body.marketsTable.header.low': '24低',
    'page.body.marketsTable.header.volume': '24卷',


    /* Landing */
    'page.body.landing.header.button1': '个人资料',
    'page.body.landing.header.button2': '登录',
    'page.body.landing.header.button3': '寄存器',

    'page.body.landing.marketInfo.title.text1': '欢迎来到B4U Wallet and Exchange.',
    'page.body.landing.marketInfo.title.text2': '买卖数字货币。',
    'page.body.landing.marketInfo.title.button': '开始交易',

    'page.body.landing.platformInfo.item.first.value': '30M+',
    'page.body.landing.platformInfo.item.first.title': '客户服务',
    'page.body.landing.platformInfo.item.second.value': '$3M+',
    'page.body.landing.platformInfo.item.second.title': '30天量',
    'page.body.landing.platformInfo.item.third.value': '101',
    'page.body.landing.platformInfo.item.third.title': '支持的国家',

    'page.body.landing.register.item.title': '无论您的经验水平如何',
    'page.body.landing.register.item.text': 'B4U Wallet and Exchange 提供了带有实时订单簿，图表工具，交易历史记录和简单订单过程的直观界面，因此您可以从第一天开始进行交易。',
    'page.body.landing.register.item.button': '寄存器',

    'page.body.landing.features.title': 'B4U Wallet and Exchange 平台功能',
    'page.body.landing.features.features.item1.title': '交换',
    'page.body.landing.features.features.item2.title': '订单类型',
    'page.body.landing.features.features.item3.title': '可定制的界面',
    'page.body.landing.features.features.item4.title': '安全',
    'page.body.landing.features.features.item5.title': '社区',
    'page.body.landing.features.features.item6.title': '行业领先的API',
    'page.body.landing.features.features.item1.text': 'B4U Wallet and Exchange 提供世界上流动性最高的订单簿，使用户能够以最小的滑点轻松交换比特币，以太坊，EOS，Litecoin，Ripple，NEO和许多其他数字资产。',
    'page.body.landing.features.features.item2.text': 'B4U Wallet and Exchange 提供了一套订单类型，为交易者提供了每种情况所需的工具。详细了解我们最先进的算法订单类型。',
    'page.body.landing.features.features.item3.text': '根据需要组织工作区：组成布局，在主题之间选择，设置通知和数据首选项。',
    'page.body.landing.features.features.item4.text': '用户信息和资金的安全是我们的首要任务。联系我们以了解有关我们的安全功能和集成的更多信息。',
    'page.body.landing.features.features.item5.text': '加入一个相信加密货币力量的全球社区。',
    'page.body.landing.features.features.item6.text': '我们的Websocket提要让您轻松访问实时市场数据，而我们的交易API则使您能够开发安全的程序化交易机器人。',

    'page.body.landing.tradeOnTheGo.item.title': '随时随地进行交易',
    'page.body.landing.tradeOnTheGo.item.text1': '我们的平台针对在任何设备上进行交易进行了优化。',
    'page.body.landing.tradeOnTheGo.item.text2': '而且您不需要下载任何其他应用程序。',
    'page.body.landing.tradeOnTheGo.item.text3': 'B4U Wallet加密货币交换的所有功能都在您的掌中。',
    'page.body.landing.tradeOnTheGo.item.button': '我们试试吧',

    'page.body.landing.startTrading.title': '立即开始交易',
    'page.body.landing.startTrading.button1': '寄存器',
    'page.body.landing.startTrading.button2': '开始交易',

    'page.body.landing.footer.exchange': '交换',
    'page.body.landing.footer.wallets': '皮夹',
    'page.body.landing.footer.fees': '费用',
    'page.body.landing.footer.faq': '常问问题',
    'page.body.landing.footer.support': '支持',
    'page.body.landing.footer.privacy': '隐私',
    'page.body.landing.footer.about': '关于我们',
    'page.body.landing.footer.community': '社区',
    'page.body.landing.footer.info': '信息',
    'page.body.landing.footer.rights': 'B4U Wallet & Exchange © 版权所有。',


    /* Wallets */

    'page.body.wallets.title': '皮夹',
    'page.body.wallets.action.deposit': '存款',
    'page.body.wallets.action.withdraw': '收回',
    'page.body.wallets.action.transfer': '转让',
    'page.body.wallets.action.trade': '贸易方式',
    'page.body.wallets.action.buy': '购买',

    'page.body.wallets.estimated_value': '估计的价值',
    'page.body.wallets.input.search.placeholder': '搜索',
    'page.body.wallets.checkbox.label.hide_balance': '隐藏小额结余',

    'page.body.wallets.table.header.coin': '硬币',
    'page.body.wallets.table.header.total': '总',
    'page.body.wallets.table.header.available': '可用的',
    'page.body.wallets.table.header.locked': '已锁定',
    'page.body.wallets.table.header.actions': '动作',

    'page.body.wallets.locked': '已锁定',
    'page.body.wallets.balance': '平衡',
    'page.body.wallets.tabs.deposit': '存款',
    'page.body.wallets.tabs.deposit.disabled.message': '存款因管理而被禁用',
    'page.body.wallets.tabs.deposit.ccy.message.submit': '使用以下地址或QR码提交存款。经过{confirmations}确认后，您的存款将反映在您的帐户中',
    'page.body.wallets.tabs.deposit.ccy.message.address': '存款地址',
    'page.body.wallets.tabs.deposit.ccy.message.button': '复制',
    'page.body.wallets.tabs.deposit.ccy.message.success': '地址已复制',
    'page.body.wallets.tabs.deposit.ccy.message.error': '生成存款地址',
    'page.body.wallets.tabs.deposit.ccy.button.generate': '生成',
    'page.body.wallets.tabs.deposit.ccy.button.address': '地址',

    'page.body.wallets.tabs.deposit.fiat.message1': '通过银行转账存款',
    'page.body.wallets.tabs.deposit.fiat.message2': '请使用以下凭据启动银行转帐。您的存款将在2个工作日内反映到您的帐户中。',
    'page.body.wallets.tabs.deposit.fiat.message3': '请在您的付款参考中使用以下参考代码。',
    'page.body.wallets.tabs.deposit.fiat.bankName': '银行名称',
    'page.body.wallets.tabs.deposit.fiat.accountNumber': '账号',
    'page.body.wallets.tabs.deposit.fiat.accountName': '用户名',
    'page.body.wallets.tabs.deposit.fiat.phoneNumber': '电话号码',
    'page.body.wallets.tabs.deposit.fiat.referenceCode': '您的参考代码',
    'page.body.wallets.table.pending': '待定',
    'page.body.wallets.table.rejected': '拒绝',
    'page.body.wallets.tabs.deposit.fiat.admin': ' 要启动法定提款，请联系管理员！',

    'page.body.wallets.tabs.withdraw': '收回',
    'page.body.wallets.tabs.convert': '买卖',
    'page.body.wallets.tabs.withdraw.content.address': '提款地址',
    'page.body.wallets.tabs.withdraw.content.amount': '提款金额',
    'page.body.wallets.tabs.withdraw.content.code2fa': '2FA代码',
    'page.body.wallets.tabs.withdraw.content.fee': '费用',
    'page.body.wallets.tabs.withdraw.content.available': '可用金额',
    'page.body.wallets.tabs.withdraw.content.total': '总提款金额',
    'page.body.wallets.tabs.withdraw.content.button': '收回',
    'page.body.wallets.tabs.withdraw.disabled.message': '提款已被管理部门禁用',
    'page.body.wallets.tabs.withdraw.amount.error': '提款金额不得超过总提款金额。 （提款金额包括每次提款{fee} {currency}的提款费）',

    'page.body.wallets.tabs.withdraw.modal.confirmation': '确认书',
    'page.body.wallets.tabs.withdraw.modal.message1': '您将收到 ',
    'page.body.wallets.tabs.withdraw.modal.message2': ' 在地址上',
    'page.body.wallets.tabs.withdraw.modal.button.cancel': '取消',
    'page.body.wallets.tabs.withdraw.modal.button.withdraw': '收回',

    'page.body.wallets.tabs.withdraw.content.enable2fa': '要退出，您必须启用2FA',
    'page.body.wallets.tabs.withdraw.content.enable2faButton': '启用2FA',

    'success.beneficiaries.created': '受益人成功创建',
    'success.beneficiaries.activated': '受益人成功激活',
    'success.beneficiaries.deleted': '受益人已成功删除',

    'page.body.wallets.beneficiaries.title': '提款地址',
    'page.body.wallets.beneficiaries.fiat.title': '提款详情',
    'page.body.wallets.beneficiaries.dropdown.address': '地址',
    'page.body.wallets.beneficiaries.dropdown.select': '选择',
    'page.body.wallets.beneficiaries.dropdown.name': '名称',

    'page.body.wallets.beneficiaries.dropdown.fiat.account': '帐户',
    'page.body.wallets.beneficiaries.dropdown.fiat.bankOfBeneficiary': '受益人银行',
    'page.body.wallets.beneficiaries.dropdown.fiat.beneficiary': '受益人',
    'page.body.wallets.beneficiaries.dropdown.fiat.description': '描述',
    'page.body.wallets.beneficiaries.dropdown.fiat.name': '名称',
    'page.body.wallets.beneficiaries.dropdown.fiat.fullName': '全名',

    'page.body.wallets.beneficiaries.addAddress': '新增地址',
    'page.body.wallets.beneficiaries.addAddressModal.header': '添加新的提款地址',

    'page.body.wallets.beneficiaries.addAddressModal.body.coinAddress': '区块链地址',
    'page.body.wallets.beneficiaries.addAddressModal.body.coinBeneficiaryName': '收款人姓名',
    'page.body.wallets.beneficiaries.addAddressModal.body.coinDescription': '说明（可选）',

    'page.body.wallets.beneficiaries.addAddressModal.body.fiatName': '描述',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatFullName': '全名',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatAccountNumber': '账号',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatBankName': '银行名称',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatBankSwiftCode': '银行Swift代码（可选）',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatIntermediaryBankName': '中介银行名称（可选）',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatIntermediaryBankSwiftCode': '中介银行Swift代码（可选）',


    'page.body.wallets.beneficiaries.addAddressModal.body.button': '提交确认',

    'page.body.wallets.beneficiaries.confirmationModal.header': '确认新地址',
    'page.body.wallets.beneficiaries.confirmationModal.body.text': '我们已经给您发送了一封包含确认码的电子邮件，请在下面输入以保存新地址：',
    'page.body.wallets.beneficiaries.confirmationModal.body.confirmationModalCode': 'PIN码',
    'page.body.wallets.beneficiaries.confirmationModal.body.button': '确认',

    'page.body.wallets.beneficiaries.tipAddress': '地址',
    'page.body.wallets.beneficiaries.tipName': '名称',
    'page.body.wallets.beneficiaries.tipDescription': '注意',

    'page.body.wallets.beneficiaries.failAddModal.header': '警告',
    'page.body.wallets.beneficiaries.failAddModal.content': '您需要确认您的帐户才能添加受益人',
    'page.body.wallets.beneficiaries.failAddModal.button': '确认帐号',

    'page.body.swap.title.swap': '交换',
    'page.body.swap.title.buy_sell': '买卖',
    'page.body.swap.available': '可用的',
    'page.body.swap.input.swap': '交换',
    'page.body.swap.input.sell': '卖',
    'page.body.swap.input.buy': '购买',
    'page.body.swap.input.tag.max': '最高',
    'page.body.swap.input.error1': '金额必须高于{amount}。',
    'page.body.swap.input.error2': '金额必须低于{amount}。',
    'page.body.swap.input.error3': '您的余额不足。',
    'page.body.swap.input.otp_code': '密码',
    'page.body.swap.input.otp_code_error': '请输入有效的otp码.',
    'page.body.swap.fee': '费用',
    'page.body.swap.price': '价钱',
    'page.body.swap.receive': '接收',
    'page.body.swap.you_will_get': '你会得到',
    'page.body.swap.button.text.swap': '交换',
    'page.body.swap.button.text.buy': '购买',
    'page.body.swap.history.title.swap_history': '掉期历史',
    'page.body.swap.history.title.buy_sell_history': '买卖历史',
    'page.body.swap.history.table.column.sell': '卖',
    'page.body.swap.history.table.column.amount': '量',
    'page.body.swap.history.table.column.buy': '购买',
    'page.body.swap.history.table.column.status': '状态',
    'page.body.swap.history.table.column.date': '日期',
    'page.body.swap.history.table.pagination.text.rows_per_page': '每页行数',

    'page.body.buy_crypto.title.buy_crypto': '购买加密',
    'page.body.buy_crypto.content1': '轻松购买比特币和其他加密货币',
    'page.body.buy_crypto.content2': '使用您的信用卡，银行转帐或Apple Pay在几分钟内购买和投资。',
    'page.body.buy_crypto.fields.buy': '我想买',
    'page.body.buy_crypto.fields.spend': '我想花',
    'page.body.buy_crypto.fields.how_much': '为此',
    'page.body.buy_crypto.submit_button.text': '购买{currencyCode}',
    'page.body.buy_crypto.modal.exit_button.text': '出口',
    'page.body.buy_crypto.field.error1': '数量不足。最低限额为{amount} {currencyCode}',
    'page.body.buy_crypto.field.error2': '超出限制。每笔交易的最大限额为{amount} {currencyCode}',

    'page.body.openOrders.tab.all': '所有',
    'page.body.openOrders.tab.open': '打开',
    'page.body.openOrders.header.orderType': '订单类型',
    'page.body.openOrders.header.orderType.buy.market': '购买市场',
    'page.body.openOrders.header.orderType.buy.limit': '购买限额',
    'page.body.openOrders.header.orderType.sell.market': '卖出市场',
    'page.body.openOrders.header.orderType.sell.limit': '限价卖出',
    'page.body.openOrders.header.pair': '对',
    'page.body.openOrders.header.amount': '量',
    'page.body.openOrders.header.price': '价钱',
    'page.body.openOrders.header.executed': '已执行',
    'page.body.openOrders.header.remaining': '剩余的',
    'page.body.openOrders.header.costRemaining': '剩余成本',
    'page.body.openOrders.header.status': '状态',
    'page.body.openOrders.content.status.done': '已执行',
    'page.body.openOrders.content.status.wait': '打开',
    'page.body.openOrders.content.status.cancel': '已停止',
    'page.body.openOrders.header.button.cancelAll': '全部取消',

    'page.body.history.deposit': '存款记录',
    'page.body.history.deposit.header.txid': 'txID',
    'page.body.history.deposit.header.date': '日期',
    'page.body.history.deposit.header.currency': '货币',
    'page.body.history.deposit.header.amount': '量',
    'page.body.history.deposit.header.status': '状态',
    'page.body.history.deposit.content.status.accepted': '公认',
    'page.body.history.deposit.content.status.collected': '集',
    'page.body.history.deposit.content.status.submitted': '已提交',
    'page.body.history.deposit.content.status.canceled': '取消',
    'page.body.history.deposit.content.status.rejected': '拒绝',
    'page.body.history.deposit.content.status.skipped': '跳过',
    'page.body.history.deposit.content.status.succeed': '成功',

    'page.body.history.withdraw': '提款历史',
    'page.body.history.withdraw.header.id': 'ID',
    'page.body.history.withdraw.header.date': '日期',
    'page.body.history.withdraw.header.currency': '货币',
    'page.body.history.withdraw.header.address': '地址',
    'page.body.history.withdraw.header.amount': '量',
    'page.body.history.withdraw.header.fee': '费用',
    'page.body.history.withdraw.header.status': '状态',
    'page.body.history.withdraw.header.txid': 'txID',
    'page.body.history.withdraw.content.status.prepared': '准备好的',
    'page.body.history.withdraw.content.status.submitted': '已提交',
    'page.body.history.withdraw.content.status.skipped': '跳过',
    'page.body.history.withdraw.content.status.canceled': '取消',
    'page.body.history.withdraw.content.status.accepted': '公认',
    'page.body.history.withdraw.content.status.suspected': '可疑',
    'page.body.history.withdraw.content.status.rejected': '拒绝',
    'page.body.history.withdraw.content.status.processing': '处理中',
    'page.body.history.withdraw.content.status.succeed': '成功',
    'page.body.history.withdraw.content.status.failed': '失败的',
    'page.body.history.withdraw.content.status.confirming': '确认中',
    'page.body.history.withdraw.content.status.errored': '错误',
    'page.body.history.withdraw.content.status.collected': '集',

    'page.body.history.trade': '交易历史',
    'page.body.history.trade.header.id': 'ID',
    'page.body.history.trade.header.date': '日期',
    'page.body.history.trade.header.side': '侧',
    'page.body.history.trade.content.side.buy': '购买',
    'page.body.history.trade.content.side.sell': '卖',
    'page.body.history.trade.header.market': '市场',
    'page.body.history.trade.header.price': '价钱',
    'page.body.history.trade.header.total': '总',
    'page.body.history.trade.header.amount': '量',
    'page.body.history.trade.header.balance': '平衡',

    // Deposit Screen
    'page.body.deposit.header.title': '存款',
    'page.body.deposit.tabs.crypto': '加密货币',
    'page.body.deposit.tabs.fiat': '菲亚特',
    'page.body.deposit.select.title': '搜索货币',
    'page.body.deposit.total_balance': '总余额',
    'page.body.deposit.tips.title': '提示',
    'page.body.deposit.tips.tip1': '如果您已存入，请注意我们发送给您的短信，网站信函和电子邮件。',
    'page.body.deposit.tips.tip2': '在{confirmations}确认之前，您的等值资产将暂时无法提取。',
    'page.body.deposit.network.title': '存款网络',
    'page.body.deposit.network.message': '请根据转账钱包的公链类型选择相应的B4U存款地址格式。请注意，某些钱包可能支持多种公共链类型的令牌转移，例如交易所钱包通常支持来自ERC20，OMNI和TRC20类型的USDT的存款。确保转移时选择的公共链网络类型与B4U存款的公共链网络类型相同。',
    'page.body.deposit.network.address.text': '{currency} 地址',
    'page.body.deposit.network.tag.text': '{currency} 标签',
    'page.body.deposit.network.tag.instruction': '请同时输入标记和地址数据，这是将{currency}成功存入您的B4U帐户所必需的.',
    'page.body.deposit.network.address.instructions.title': '仅发送{currency}到该存款地址。',
    'page.body.deposit.network.address.instructions.description': '将{currency}以外的硬币或令牌发送到此地址可能会导致您的存款丢失。',

    'page.body.withdraw.header.title': '收回',
    'page.body.withdraw.tabs.crypto': '加密货币',
    'page.body.withdraw.tabs.fiat': '菲亚特',
    'page.body.withdraw.select.title': '搜索货币',
    'page.body.withdraw.total_balance': '总余额',
    'page.body.withdraw.tips.title': '提示',
    'page.body.withdraw.tips.tip1': '请勿直接提取到众筹或ICO地址，因为您的帐户将不会获得此类销售的代币。',
    'page.body.withdraw.tips.tip2': "提现到B4U用户的地址时，默认情况下，手续费将退还至往来帐户。",

    'page.body.profile.header.account': '个人资料',

    'page.body.profile.title': '个人资料管理',
    'page.body.profile.tabs.security': '个人资料/安全性',
    'page.body.profile.tabs.identification': '身份证明',
    'page.body.profile.tabs.referral': '转介',
    'page.body.profile.tabs.api_management': 'API管理',
    'page.body.profile.tabs.activity': '活动',

    'page.body.profile.tabs.referral.title': '邀请朋友并一起获得加密货币',
    'page.body.profile.tabs.referral.description': '每次与B4U进行交易时，您都可以赚取高达40％的佣金。',
    'page.body.profile.tabs.referral.instruction.title': '立即邀请',
    'page.body.profile.tabs.referral.instruction.description': '使用您的唯一链接通过消息或电子邮件邀请您的朋友。您的默认邀请代码也可以在现实生活中共享或作为屏幕截图共享。',

    'page.body.profile.tabs.referral.id': '推荐人ID',
    'page.body.profile.tabs.referral.link': '推荐链接',

    'page.body.profile.header.account.content.password': '密码',
    'page.body.profile.header.account.content.password.button.change': '更改',
    'page.body.profile.header.account.content.password.old': '旧密码',
    'page.body.profile.header.account.content.password.new': '新密码',
    'page.body.profile.header.account.content.password.button.save': '救',
    'page.body.profile.header.account.content.password.button.cancel': '取消',
    'page.body.profile.header.account.content.password.conf': '确认密码',
    'page.body.profile.header.account.content.password.dont.match': '密码不匹配',
    'page.body.profile.header.account.content.password.change.success': '成功！',
    'page.body.profile.header.account.content.password.change': '更改密码',

    'page.body.profile.header.account.content.twoFactorAuthentication': '2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.enable': '已启用',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.disable': '残障人士',
    'page.body.profile.header.account.content.twoFactorAuthentication.header': 'Google身份验证器',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.1': '从下载并安装Google Authenticator应用程序 ',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.or': '要么 ',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.2': '扫描QR码或使用秘密的MFA代码：',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.3': '* 将此机密保存在安全的位置。此代码可用于从其他设备获得2FA访问。',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.mfa': 'MFA 码',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.4': '从应用程序中输入2fa代码',
    'page.body.profile.header.account.content.twoFactorAuthentication.subHeader': '2FA 码',
    'page.body.profile.header.account.content.twoFactorAuthentication.enable': '启用',
    'page.body.profile.header.account.content.twoFactorAuthentication.disable': '禁用 2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.modalBody': '请联系管理员以禁用',
    'page.body.profile.header.account.content.twoFactorAuthentication.modalHeader': '启用两因素身份验证',

    'page.body.profile.header.account.profile': '个人资料验证',
    'page.body.profile.header.account.profile.email.title': '邮件已验证',
    'page.body.profile.header.account.profile.email.message': '启用存款/提款',
    'page.body.profile.header.account.profile.phone.unverified.title': '电话验证',
    'page.body.profile.header.account.profile.phone.title': '电话已验证',
    'page.body.profile.header.account.profile.phone.message': '启用存款/交易',
    'page.body.profile.header.account.profile.identity.unverified.title': '身份验证',
    'page.body.profile.header.account.profile.identity.title': '身份验证',
    'page.body.profile.header.account.profile.identity.message': '提款已启用',

    'page.body.profile.header.referralProgram': '推荐链接',
    'page.body.profile.content.copyLink': '复制',

    'page.body.profile.apiKeys.header': '我的API金钥',
    'page.body.profile.apiKeys.header.create': '创建新的',

    'page.body.profile.apiKeys.noOtp': '请启用两因素身份验证',
    'page.body.profile.apiKeys.show': '节目',
    'page.body.profile.apiKeys.noKeys': '您没有API密钥',

    'page.body.profile.apiKeys.modal.btn.show': '节目',
    'page.body.profile.apiKeys.modal.btn.create': '确认',
    'page.body.profile.apiKeys.modal.btn.copy': '复制',
    'page.body.profile.apiKeys.modal.btn.activate': '启用',
    'page.body.profile.apiKeys.modal.btn.disabled': '禁用',
    'page.body.profile.apiKeys.modal.btn.delete': '删除',
    'page.body.profile.apiKeys.modal.header': '2FA 验证',
    'page.body.profile.apiKeys.modal.created_header': '已建立',
    'page.body.profile.apiKeys.modal.access_key': '访问密钥',
    'page.body.profile.apiKeys.modal.secret_key': '密钥',
    'page.body.profile.apiKeys.modal.secret_key_info': '此信息将仅显示一次，并且一旦丢失将无法检索。',
    'page.body.profile.apiKeys.modal.secret_key_store': '请妥善存放。',
    'page.body.profile.apiKeys.modal.note': '注意',
    'page.body.profile.apiKeys.modal.note_content': `为避免资产损失，请勿将您的秘密密钥和私钥告诉他人。\
 If you forget you Secret Key, please delete it and apply for a new Secret Key pair.`,
    'page.body.profile.apiKeys.modal.title': '从应用程序中输入2fa代码',
    'page.body.profile.apiKeys.modal.label': '6位Google身份验证器代码',
    'page.body.profile.apiKeys.modal.placeholder': '输入验证码',

    'page.body.profile.apiKeys.table.header.kid': '小子',
    'page.body.profile.apiKeys.table.header.algorithm': '算法',
    'page.body.profile.apiKeys.table.header.state': '州',
    'page.body.profile.apiKeys.table.header.created': '已建立',
    'page.body.profile.apiKeys.table.header.updated': '更新',

    'success.api_keys.fetched': '成功获取API密钥',
    'success.api_keys.created': 'API密钥已创建',
    'success.api_keys.copied.access': '访问密钥已复制',
    'success.api_keys.copied.secret': '密钥已复制',
    'success.api_keys.updated': 'API密钥已更新',
    'success.api_keys.deleted': 'API密钥已删除',

    'page.body.profile.header.accountActivity': '帐户活动',
    'page.body.profile.header.accountActivity.content.date': '日期',
    'page.body.profile.header.accountActivity.content.addressip': '地址IP',
    'page.body.profile.header.accountActivity.content.action': '行动',
    'page.body.profile.header.accountActivity.content.result': '结果',
    'page.body.profile.header.accountActivity.content.userAgent': '用户代理',

    'page.body.profile.content.action.login': '登录',
    'page.body.profile.content.action.logout': '登出',
    'page.body.profile.content.action.request2fa': '要求2FA的QR码',
    'page.body.profile.content.action.enable2fa': '启用 2FA',
    'page.body.profile.content.action.login.2fa': '用2FA登录',
    'page.body.profile.content.action.requestPasswordReset': '要求重设密码',
    'page.body.profile.content.action.passwordReset': '重设密码',

    'page.body.profile.content.result.succeed': '成功',
    'page.body.profile.content.result.failed': '失败的',
    'page.body.profile.content.result.denied': '被拒绝',

    'page.body.kyc.phone.head': '验证电话',
    'page.body.kyc.phone.enterPhone': '输入电话号码',
    'page.body.kyc.phone.phoneNumber': '电话号码',
    'page.body.kyc.phone.enterCode': '输入确认码',
    'page.body.kyc.phone.code': '短信代码',
    'page.body.kyc.phone.send': '发送代码',
    'page.body.kyc.phone.resend': '重新发送验证码',
    'page.body.kyc.identity.firstName': '名字',
    'page.body.kyc.identity.lastName': '姓',
    'page.body.kyc.identity.dateOfBirth': '出生日期',
    'page.body.kyc.identity.residentialAddress': '居住地址',
    'page.body.kyc.identity.city': '市',
    'page.body.kyc.identity.postcode': '邮编',
    'page.body.kyc.identity.nationality': '国籍',
    'page.body.kyc.identity.CoR': '居住国家',
    'page.body.kyc.documents.expiryDate': '到期日',
    'page.body.kyc.documents.drag': '拖放或浏览文件',
    'page.body.kyc.documents.maxFile': '档案大小上限为10MB',
    'page.body.kyc.documents.maxNum': '档案数量上限为5',
    'page.body.kyc.documents.upload': '上载您的带照片的身份证',
    'page.body.kyc.documents.select.passport': '护照',
    'page.body.kyc.documents.select.identityCard': '身份证',
    'page.body.kyc.documents.select.driverLicense': '驾驶执照',
    'page.body.kyc.documents.select.utilityBill': '水电费',
    'page.body.kyc.documents.number': ': 数',
    'page.body.kyc.documentsType': '文件类型',

    'page.body.kyc.next': '下一个',
    'page.body.kyc.submit': '提交',
    'page.body.kyc.head.phone': '电话验证',
    'page.body.kyc.head.identity': '身份验证',
    'page.body.kyc.head.document': '文件验证',

    'page.body.lock.oops': '糟糕！',
    'page.body.lock.expired': '您的审判似乎已过期',
    'page.body.lock.license': '您的审判似乎已过期',
    'page.body.lock.visit': '访问',

    'page.footer.legalDocuments': '合法文件',
    'page.footer.faq': '常问问题',

    'page.header.signIn': '登入',
    'page.header.signIn.email': '电子邮件',
    'page.header.signIn.password': '密码',
    'page.header.signIn.password.message.error': '密码无效',
    'page.header.signIn.receiveConfirmation': '没有收到您的确认电子邮件吗？',
    'page.header.signIn.forgotPassword': '忘记密码了吗？',
    'page.header.signIn.resetPassword.title': '重设密码',
    'page.header.signIn.resetPassword.newPassword': '新密码',
    'page.header.signIn.resetPassword.repeatPassword': '重复输入密码',
    'page.header.signIn.resetPassword.button': '更改',
    'page.header.signIn.resetPassword.error': '字段为空或不匹配',

    'page.header.signUp': '注册',
    'page.header.signUp.email': '电子邮件',
    'page.header.signUp.email.message.error': '电邮无效',
    'page.header.signUp.password': '密码',
    'page.header.signUp.password.message.error': '密码必须至少包含8个字符，至少一个大写字母和一位数字',
    'page.header.signUp.confirmPassword': '确认密码',
    'page.header.signUp.confirmPassword.message.error': '密码不匹配',
    'page.header.signUp.referalCode': '推荐码',
    'page.header.signUp.terms': '我已阅读并同意服务条款',
    'page.header.signUp.modal.header': '确认你的邮件地址',
    'page.header.signUp.modal.body': 'To complete registration, check for an ' +
                                      'email in your inbox with further ' +
                                      'instruction. If you cannot find the email, ' +
                                      'please check your spam folder',
    'page.header.signUp.modal.footer': '好',
    'page.header.signUp.strength.password': '密码强度',
    'page.header.signUp.password.too.weak': '太弱',
    'page.header.signUp.password.weak': '弱',
    'page.header.signUp.password.good': '好',
    'page.header.signUp.password.strong': '强大',
    'page.header.signUp.password.very.strong': '非常强壮',
    'page.resendConfirmation': '重发确认',
    'page.forgotPassword': '忘记密码',
    'page.forgotPassword.message': '输入电子邮件以重置密码',
    'page.password2fa': '2FA 验证',
    'page.password2fa.message': '从应用程序中输入2fa代码',
    'page.forgotPassword.email': '电子邮件',
    'page.forgotPassword.send': '发送',
    'page.noDataToShow': '没有数据可显示',

    'page.modal.withdraw.success': '成功！',
    'page.modal.withdraw.success.message.content': '您的提款要求已经收到',
    'page.modal.withdraw.success.button': '好',

    'page.modal.expired.title': '您的会话已过期',
    'page.modal.expired.submit': '重新登入',


    /* Customization */
    'page.body.customization.tabs.themes': '色彩',
    'page.body.customization.tabs.fonts': '字型',
    'page.body.customization.tabs.spacing': '间距',
    'page.body.customization.tabs.images': '图片',
    'page.body.customization.comingSoon': '快来了',
    'page.body.customization.actionButtons.reset': '重启',
    'page.body.customization.actionButtons.save': '救',

    'page.body.customization.themes.selector.label': '主题预设',

    'page.body.customization.themes.color.mainBackgroundColor': '主要背景',
    'page.body.customization.themes.color.bodyBackgroundColor': '身体背景',
    'page.body.customization.themes.color.headerBackgroundColor': '标头背景',
    'page.body.customization.themes.color.subheaderBackgroundColor': '副标题背景',
    'page.body.customization.themes.color.dropdownBackgroundColor': '下拉背景',
    'page.body.customization.themes.color.icon': '图示',
    'page.body.customization.themes.color.primaryCtaColor': '主要号召性用语',
    'page.body.customization.themes.color.contrastCtaColor': '对比号召性用语',
    'page.body.customization.themes.color.secondaryContrastCtaColor': '二次对比CTA',
    'page.body.customization.themes.color.ctaLayerColor': 'CTA层',
    'page.body.customization.themes.color.systemGreen': '系统绿色',
    'page.body.customization.themes.color.systemRed': '系统红',
    'page.body.customization.themes.color.systemYellow': '系统黄',
    'page.body.customization.themes.color.asks': '问颜色',
    'page.body.customization.themes.color.bids': '出价颜色',
    'page.body.customization.themes.color.primaryTextColor': '主要文字',
    'page.body.customization.themes.color.textContrastColor': '文字对比',
    'page.body.customization.themes.color.inputBackgroundColor': '输入背景',
    'page.body.customization.themes.color.dividerColor': '分频器颜色',
    'page.body.customization.themes.color.shadowColor': '阴影颜色',
    'page.body.customization.themes.color.landingBackgroundColor': '登陆背景',
    'page.body.customization.themes.color.strengthMeterVeryStrong': '密码很强',

    'page.body.customization.themes.theme.darkBlue.title': '深蓝',
    'page.body.customization.themes.theme.darkRed.title': '深红',
    'page.body.customization.themes.theme.purple.title': '紫色',
    'page.body.customization.themes.theme.green.title': '绿色',


    // success messages
    'success.documents.accepted': '文件上传成功',
    'success.identity.accepted': '身份上传成功',
    'success.withdraw.action': '收到提款要求',
    'success.otp.enabled': '2FA已启用',
    'success.otp.disabled': '2FA已禁用',
    'success.password.changed': '密码已更改',
    'success.password.forgot': '密码重置链接已发送至您的电子邮件',
    'success.password.changed.successfuly': '密码已更改',
    'success.order.cancelling': '订单被取消',
    'success.order.canceled': '订单已取消',
    'success.order.canceled.all': '所有订单已取消',
    'success.order.cancelling.all': '所有订单都被取消',
    'success.phone.verification.send': '验证码已发送到您的手机',
    'success.phone.confirmed': '您的电话已确认',
    'success.phone.confirmation.message': '成功！',
    'success.message.sent': '消息已发送',
    'success.email.confirmed': '您的电子邮件地址已成功确认',
    'success.order.created': '订单已创建',
    'success.order.done': '订单成功完成',
    'success.data.changed.language': '语言已更改',

    // error messages
    'error.order.rejected': '订单被拒绝',
    'error.invalid_request': '订单-无效的请求',
    'error.bad_request': '订单-错误的要求',
    'error.request_entity_too_large': '订单-请求实体太大',

    // barong
    'resource.labels.private': '无法更新标签',
    'resource.user.no_activity': '没有活动记录或主题错误',
    'resource.profile.not_exist': '用户没有个人资料',
    'resource.profile.exist': '个人资料已存在',
    'resource.api_key.2fa_disabled': '仅启用了2FA的帐户被禁用',
    'resource.api_key.missing_otp': '该帐户已启用2FA，但缺少OTP代码',
    'resource.api_key.invalid_otp': 'OTP代码无效',
    'resource.phone.twillio': 'Twilio客户端出了点问题',
    'resource.phone.invalid_num': '电话号码无效',
    'resource.phone.exists': '电话号码未验证。重新发送了验证码。',
    'resource.phone.number_exist': '电话号码已存在',
    'resource.phone.verification_invalid': '找不到电话或验证码无效',
    'resource.documents.limit_reached': '已经达到的最大文件数',
    'resource.documents.limit_will_be_reached': '此次上传文件数量将达到限制',
    'resource.otp.already_enabled':'此帐户已启用2FA',
    'resource.otp.invalid': 'OTP代码无效',
    'resource.password.doesnt_match': '新密码不匹配',
    'resource.password.prev_pass_not_correct': '先前的密码不正确',
    'resource.password.no_change_provided': '新密码不能与旧密码相同',
    'resource.document.empty_doc_expire': '到期日期无效',
    'password.requirements': '密码不符合最低要求',
    'password.password.password_strength': '密码太弱',

    'email.taken': '电子邮件已经被接收',

    'identity.user.invalid_referral_format': '无效的引荐UID格式',
    'identity.user.referral_doesnt_exist': '推荐人不存在',
    'identity.user.active_or_doesnt_exist': '用户不存在或已被激活',
    'identity.password.user_doesnt_exist': '用户不存在',
    'identity.user.passwords_doesnt_match': '密码不匹配',
    'identity.user.utilized_token': 'JWT已经被使用',
    'identity.session.invalid_login_params': '无效的电子邮件或密码',
    'identity.session.invalid': '无效会话',
    'identity.captcha.required': 'captcha_response是必需的',
    'identity.captcha.mandatory_fields': '必填项必须填写',
    'identity.session.not_active': '您的帐户无效',
    'identity.session.banned': '您的帐户被禁止',
    'identity.session.invalid_params': '无效的电子邮件或密码',
    'identity.session.missing_otp': '该帐户已启用2FA，但缺少OTP代码',
    'identity.session.invalid_otp': 'OTP代码无效',

    'first_name.invalid': '名无效',
    'last_name.invalid': '姓氏无效',
    'city.invalid': '城市无效',
    'postcode.invalid': '邮政编码无效',
    'address.invalid': '地址无效',
    'first_name.blank': '名缺失或为空',
    'last_name.blank': '姓氏缺失或为空',
    'dob.blank': '出生日期无效',
    'address.blank': '地址丢失或为空',
    'city.blank': '城市缺失或空着',
    'country.blank': '国家缺少或为空',
    'postcode.blank': 'Postcode.blank丢失或为空',
    'country.must have alpha2 or alpha3 format': '国家/地区必须采用alpha2或alpha3格式',

    'totp.error': 'OTP代码无效',

    'record.not_found': '找不到记录',
    'jwt.decode_and_verify': '无法解码和验证JWT',
    'authz.invalid_session': '无法解码Cookie',
    'authz.user_not_active': '用户不活跃',
    'authz.invalid_signature': 'API密钥标头“签名”无效',
    'authz.apikey_not_active': 'API密钥状态为“无效”',
    'authz.disabled_2fa': 'API密钥所有者已禁用2FA',
    'authz.invalid_api_key_headers': '空白或缺少API密钥标头',
    'authz.permission_denied': '路径已列入黑名单',
    'authz.unexistent_apikey': 'X-Auth-Apikey标头无效',
    'authz.client_session_mismatch': '会话不匹配',
    'authz.csrf_token_mismatch': 'CSRF令牌不匹配',

    // validation errors
    // identity module
    'identity.user.missing_email': '电子邮件丢失',
    'identity.user.empty_email': '电子邮件丢失或为空',
    'identity.user.missing_password': '密码遗失',
    'identity.user.empty_password': '密码丢失或为空',
    'identity.user.missing_token': '令牌丢失',
    'identity.user.empty_token': '令牌丢失或为空',
    'identity.user.missing_reset_password_token': '重置密码令牌丢失',
    'identity.user.empty_reset_password_token': '重置密码令牌丢失或为空',
    'identity.user.missing_confirm_password': '确认密码丢失',
    'identity.user.empty_confirm_password': '确认密码丢失或为空',

    'identity.session.missing_emai': '电子邮件丢失',
    'identity.session.missing_password': '密码遗失',
    'identity.session.invalid_captcha_format': '验证码格式无效',

    // resource module
    'resource.otp.missing_code': '缺少OTP代码',
    'resource.otp.empty_code': 'OTP代码丢失或为空',

    'resource.labels.missing_key': '钥匙不见了',
    'resource.labels.empty_key': '密钥丢失或为空',
    'resource.labels.missing_value': '价值缺失',
    'resource.labels.empty_value': '值缺失或为空',

    'resource.documents.missing_doc_expire': '缺少文件的到期日期',
    'resource.documents.empty_doc_expire': '文件到期日期缺失或为空',
    'resource.documents.missing_doc_type': '文件类型遗失',
    'resource.documents.empty_doc_type': '文件类型遗失或空白',
    'resource.documents.missing_doc_number': '文件编号遗失',
    'resource.documents.empty_doc_number': '文件编号遗失或空白',
    'resource.documents.missing_upload': '附件丢失',


    'resource.user.missing_topic': '缺少主题',
    'resource.user.empty_topic': '主题丢失或为空',
    'resource.user.missing_old_password': '旧密码丢失',
    'resource.user.empty_old_password': '旧密码丢失或为空',
    'resource.user.missing_new_password': '缺少新密码',
    'resource.user.empty_new_password': '新密码丢失或为空',
    'resource.user.missing_confirm_password': '确认密码丢失',
    'resource.user.empty_confirm_password': '确认密码丢失或为空',

    'resource.profile.missing_first_name': '名字遗失',
    'resource.profile.missing_last_name': '姓氏丢失',
    'resource.profile.missing_dob': '出生日期不详',
    'resource.profile.missing_address': '地址丢失',
    'resource.profile.missing_postcode': '邮政编码缺失',
    'resource.profile.missing_city': '城市失踪',
    'resource.profile.missing_country': '缺少国家',

    'resource.api_key.missing_algorithm': '缺少算法',
    'resource.api_key.empty_algorithm': '算法丢失或为空',
    'resource.api_key.empty_kid': 'KID缺失或为空',
    'resource.api_key.empty_scope': '范围缺失或为空',
    'resource.api_key.missing_totp': '缺少TOTP代码',
    'resource.api_key.empty_totp': 'TOTP代码丢失或为空',
    'resource.api_key.missing_kid': 'KID丢失',
    'resource.api_key.empty_state': '状态缺失或为空',

    'resource.phone.missing_phone_number': '电话号码丢失',
    'resource.phone.empty_phone_number': '电话号码丢失或为空',
    'resource.phone.missing_verification_code': '验证码丢失',
    'resource.phone.empty_verification_code': '验证码丢失或为空',

    // peatio
    'account.currency.doesnt_exist': '货币不存在',
    'account.deposit.invalid_state': '存入无效状态',
    'account.deposit.non_integer_limit': '您发送的值无法解析为Integer类型',
    'account.deposit.invalid_limit': '无效的限制',
    'account.deposit.non_positive_page': '页面值必须为正',
    'account.deposit.empty_txid': 'Txid丢失，txid为空',
    'account.deposit_address.invalid_address_format': '无效的存款地址格式',
    'account.deposit_address.doesnt_support_cash_address_format': '货币不支持现金地址格式',
    'account.withdraw.non_integer_limit': '您发送的限值无法解析为整数类型',
    'account.withdraw.invalid_limit': '无效的限制',
    'account.withdraw.non_positive_page': '页面值必须为正',
    'account.withdraw.non_integer_otp': '不能将otp值解析为整数类型',
    'account.withdraw.empty_otp': '缺少otp，otp为空',
    'account.withdraw.empty_rid': 'Rid缺失，Rid为空',
    'account.withdraw.non_decimal_amount': '您发送的金额值无法解析为十进制类型',
    'account.withdraw.non_positive_amount': '金额必须为正',
    'account.deposit.not_permitted': '电话验证后允许入金',
    'account.withdraw.not_permitted': '请通过相应的验证步骤以提取资金',
    'account.withdraw.insufficient_balance': '帐户余额不足',
    'account.withdraw.invalid_amount': '无效的提款金额',
    'account.withdraw.create_error': '创建提款失败',
    'account.withdraw.invalid_otp': '无效的otp',
    'account.withdraw.disabled_api': '提款API已禁用',
    'account.exchanges.exchange.success': '已收到交换请求。',
    'account.exchange.non_positive_amount': '交换字段应为正值。',

    'market.market.doesnt_exist': '市场不存在',
    'market.order.invalid_state': '无效的存款状态',
    'market.order.invalid_limit': '无效的限制',
    'market.order.non_integer_limit': '您发送的限值无法解析为整数类型',
    'market.trade.empty_page': '页面丢失或为空',
    'market.order.invalid_order_by': '无效的order_by',
    'market.order.invalid_side': '无效的订单侧',
    'market.order.non_decimal_volume': '您发送的音量值无法解析为十进制类型',
    'market.order.non_positive_volume': '音量值必须为正',
    'market.order.invalid_type': '无效的订单类型',
    'market.order.non_decimal_price': '您发送的音量值无法解析为十进制类型',
    'market.order.non_positive_price': '音量值必须为正',
    'market.order.non_integer_id': '您发送的ID值无法解析为Integer类型',
    'market.order.empty_id': 'ID缺失或为空',
    'market.trade.non_integer_limit': '您发送的限值无法解析为整数类型',
    'market.trade.invalid_limit': '无效的限制',
    'market.trade.non_integer_timestamp': '您发送的时间戳值无法解析为Integer类型',
    'market.trade.empty_timestamp': '时间戳丢失或为空',
    'market.trade.invalid_order_by': '无效的order_by',
    'market.order.insufficient_market_liquidity': '市场流动性不足 ',
    'market.order.invalid_volume_or_price': '无效的数量或价格',
    'market.order.create_error': '创建错误失败',
    'market.order.cancel_error': '无法取消错误',
    'market.order.market_order_price': '市场订单没有价格',
    'market.trade.not_permitted': '请通过相应的验证步骤以启用交易',
    'market.account.insufficient_balance': '帐户余额不足',

    'public.currency.doesnt_exist': '货币不存在',
    'public.currency.invalid_type': '无效的货币类型',
    'public.market.doesnt_exist': '市场不存在',
    'public.order_book.non_integer_ask_limit': '您发送的要价极限值无法解析为整数类型',
    'public.order_book.invalid_ask_limit': '无效的要求限制',
    'public.order_book.non_integer_bid_limit': '您发送的出价limir值无法解析为Integer类型',
    'public.order_book.invalid_bid_limit': '无效的出价上限',
    'public.trade.non_integer_limit': '您发送的限值无法解析为整数类型',
    'public.trade.invalid_limit': '无效的限制',
    'public.trade.non_positive_page': '页面值必须为正',
    'public.trade.non_integer_timestamp': '您发送的时间戳值无法解析为Integer类型',
    'public.trade.invalid_order_by': '订单无效',
    'public.market_depth.non_integer_limit': '您发送的限值无法解析为整数类型',
    'public.market_depth.invalid_limit': '无效的限制',
    'public.k_line.non_integer_period': '您发送的限值无法解析为整数类型',
    'public.k_line.invalid_period': '无效期间',
    'public.k_line.non_integer_time_from': '您发送的限值无法解析为整数类型',
    'public.k_line.empty_time_from': 'Time_from参数缺失或为空',
    'public.k_line.non_integer_time_to': '您发送的限值无法解析为整数类型',
    'public.k_line.empty_time_to': 'Time_to参数丢失或为空',
    'public.k_line.non_integer_limit': '您发送的限值无法解析为整数类型',
    'public.k_line.invalid_limit': '无效的限制',

    'server.internal_error': '内部服务器错误',
    'Server error': '内部服务器错误',

    'password.strength.tip.influence': '对密码强度有什么影响',
    'password.strength.tip.number.characters': '至少8个字符',
    'password.strength.tip.letter': '至少一个大写和小写字母',
    'password.strength.tip.digit': '至少一位数字',

    ...nationalitiesNames,
};