import { LangType } from '../../translations';
import { nationalitiesNames } from '../../translations/nationalities';

export const ml: LangType = {
    //General Messages
    'no.record.found': 'Tiada Rekod Ditemui.',
    'page.body.copy': 'Salinan',
    'page.body.copied': 'Disalin',
    'copied.to.clipboard': 'Disalin ke papan keratan.',
    'learn.more': 'Learn more',
    'need.to.enable.2fa.title': 'Anda perlu mengaktifkan 2FA sebelum menggunakan ciri ini.',
    'need.to.enable.2fa.button.title': 'Dayakan 2FA',
    'need.to.confirm.account': 'Anda perlu mengesahkan akaun anda sebelum menggunakan ciri ini.',
    'need.to.confirm.account.button.title': 'Sahkan Akaun',
    'request.limit.exceeds': 'Had permintaan melebihi.',
    //End General text

    'page.header.navbar.signIn': 'Log Masuk',
    'page.header.navbar.trade': 'Berdagang',
    'page.header.navbar.wallets': 'Pelbagai Dompet',
    'page.header.navbar.swap': 'Pertukaran',
    'page.header.navbar.buy_sell': 'Beli/Jual',
    'page.header.navbar.openOrders': 'Pesanan',
    'page.header.navbar.history': 'Sejarah',

    'page.header.navbar.profile': 'Profil',
    'page.header.navbar.logout': 'Log Keluar',

    // Header Profile Links
    'page.header.navbar.profile.security': 'Keselamatan',
    'page.header.navbar.profile.identification': 'Pengenalan',
    'page.header.navbar.profile.api_management': 'Pengurusan API',
    'page.header.navbar.profile.referal': 'Penajaan',
    'page.header.navbar.profile.activities': 'Aktiviti',

    //Header Notification Tab
    'page.header.navbar.notifications.title': 'Pemberitahuan',
    'page.header.navbar.notifications.empty.content1': "Kemaskini Berjaya!",
    'page.header.navbar.notifications.empty.content2': 'Layari setiap hari untuk berita berkaitan kripto, paparan harga terkini, dan banyak lagi',
    'page.header.navbar.notifications.detail.seen.button.text': 'saya faham',
    

    'page.sidebar.group.text': 'Fee Kumpulan:',
    'page.sidebar.group.value': 'Pangkalan',

    'page.body.trade.header.market': 'Pasaran',
    'page.body.trade.header.yours': 'Milik Anda',

    'page.body.trade.header.markets': 'Pelbagai Pasaran',
    'page.body.trade.header.markets.content.market': 'Pasaran',
    'page.body.trade.header.markets.content.pair': 'Perbandingan',
    'page.body.trade.header.markets.content.price': 'Harga',
    'page.body.trade.header.markets.content.last_price': 'Harga Akhir',
    'page.body.trade.header.markets.content.change': 'Tukar',
    'page.body.trade.header.markets.content.search': 'Carian',
    'page.body.trade.header.markets.content.volume': 'Jumlah',

    'page.body.trade.header.newOrder': 'Pesanan Daripada',
    'page.body.trade.header.newOrder.content.tabs.buy': 'Beli',
    'page.body.trade.header.newOrder.content.tabs.sell': 'Jual',
    'page.body.trade.header.newOrder.content.orderType': 'Jenis Pesanan',
    'page.body.trade.header.newOrder.content.orderType.limit': 'Had Limit',
    'page.body.trade.header.newOrder.content.orderType.market': 'Pasaran',
    'page.body.trade.header.newOrder.content.price': 'Harga',
    'page.body.trade.header.newOrder.content.amount': 'Jumlah',
    'page.body.trade.header.newOrder.content.total': 'Jumlah Keseluruhan',
    'page.body.trade.header.newOrder.content.available': 'Masih Ada',

    'error.order.create.minAmount': 'Jumlah yang dimasukkan terlalu Rendah. Jumlah Minimum adalah  {amount} {currency}',
    'error.order.create.minPrice': 'Harga terlalu Rendah. Jumlah Minimum adalah  {price} {currency}',
    'error.order.create.maxPrice': 'Harga terlalu Tinggi. Jumlah Maksimum adalah  {price} {currency}',
    'error.order.create.available': 'Melebihi baki sedia ada. Baki yang ada adalah {available} {currency}',

    'page.body.trade.header.marketDepths': 'Pasaran Yang Lebih Dalam',
    'page.body.trade.header.marketDepths.content.price': 'Harga:',
    'page.body.trade.header.marketDepths.content.volume': 'Jumlah:',
    'page.body.trade.header.marketDepths.content.cumulativeVolume': 'Jumlah Keseluruhan:',
    'page.body.trade.header.marketDepths.content.cumulativeValue': 'Nilai Anggaran:',

    'page.body.trade.toolBar.lowest': 'Terendah 24jam',
    'page.body.trade.toolBar.lastPrice': 'Harga Akhir',
    'page.body.trade.toolBar.selectMarket': 'Pilihan Pasaran',
    'page.body.trade.toolBar.highest': 'Tertinggi 24jam',
    'page.body.trade.toolBar.volume': 'Nilai Semasa 24jam',
    'page.body.trade.toolBar.change': 'Ubah',

    'page.body.trade.header.asks': 'Tanya',
    'page.body.trade.header.bids': 'Bida',
    'page.body.trade.orderbook': 'Buku Pesanan',
    'page.body.trade.orderbook.lastMarket': 'Harga Pasaran Terakhir',
    'page.body.trade.orderbook.header.price': 'Harga',
    'page.body.trade.orderbook.header.amount': 'Jumlah',
    'page.body.trade.orderbook.header.volume': 'Jumlah Keseluruhan',

    'page.body.trade.header.recentTrades': 'Perdagangan Terkini',
    'page.body.trade.header.recentTrades.content.time': 'Masa',
    'page.body.trade.header.recentTrades.content.price': 'Harga',
    'page.body.trade.header.recentTrades.content.amount': 'Jumlah',

    'page.body.trade.header.openOrders': 'Pesanan dibuka',
    'page.body.trade.header.openOrders.content.date': 'Tarikh',
    'page.body.trade.header.openOrders.content.state': 'Penyata',
    'page.body.trade.header.openOrders.content.price': 'Harga',
    'page.body.trade.header.openOrders.content.amount': 'Jumlah',
    'page.body.trade.header.openOrders.content.total': 'Nilai Keseluruhan',
    'page.body.trade.header.openOrders.content.filled': 'Telah Diisi',


    /* Markets Table */
    'page.body.marketsTable.filter.all': 'KESELURUHAN',
    'page.body.marketsTable.header.pair': 'Pasaran',
    'page.body.marketsTable.header.lastPrice': 'Harga Akhir',
    'page.body.marketsTable.header.change': 'Perubahan 24jam',
    'page.body.marketsTable.header.high': 'Tertinggi 24jam',
    'page.body.marketsTable.header.low': 'Terendah 24jam',
    'page.body.marketsTable.header.volume': 'Nilai Jumlah 24jam',


    /* Landing */
    'page.body.landing.header.button1': 'profil',
    'page.body.landing.header.button2': 'log masuk',
    'page.body.landing.header.button3': 'pendaftaran',

    'page.body.landing.marketInfo.title.text1': 'Selamat Datang ke B4U Wallet and Exchange.',
    'page.body.landing.marketInfo.title.text2': 'Beli, Jual dan Berdagang dengan Mata Wang Digital.',
    'page.body.landing.marketInfo.title.button': 'mula berdagang',

    'page.body.landing.platformInfo.item.first.value': '30M+',
    'page.body.landing.platformInfo.item.first.title': 'Khidmat Pelanggan',
    'page.body.landing.platformInfo.item.second.value': '$3M+',
    'page.body.landing.platformInfo.item.second.title': 'Jumlah dalam 30 hari',
    'page.body.landing.platformInfo.item.third.value': '101',
    'page.body.landing.platformInfo.item.third.title': 'Negara Yang Terlibat',

    'page.body.landing.register.item.title': 'Tidak kira tahap pengalaman anda',
    'page.body.landing.register.item.text': 'B4U Wallet and Exchange menawarkan antara muka intuitif dengan buku pesanan masa nyata, alat membuat carta, sejarah perdagangan, dan proses pesanan mudah supaya anda dapat berdagang dari hari pertama.',
    'page.body.landing.register.item.button': 'pendaftaran',

    'page.body.landing.features.title': 'Ciri-ciri platfrom B4U Wallet and Exchange',
    'page.body.landing.features.features.item1.title': 'Pertukaran',
    'page.body.landing.features.features.item2.title': 'Jenis Pesanan',
    'page.body.landing.features.features.item3.title': 'Interface yang bersesuaian',
    'page.body.landing.features.features.item4.title': 'Keselamatan',
    'page.body.landing.features.features.item5.title': 'Komuniti',
    'page.body.landing.features.features.item6.title': 'Pemacu Industri API',
    'page.body.landing.features.features.item1.text': 'B4U Wallet and Exchange menawarkan proses pertukaran aset digital yang termudah di dunia, yang membolehkan pengguna menukar Bitcoin, Ethereum, BitcoinCash, Litecoin, Ripple dan banyak aset digital lain dengan mudah dengan slip minimum.',
    'page.body.landing.features.features.item2.text': 'B4U Wallet and Exchange menawarkan pelbagai jenis kaedah berdagang sesuai mengikut keadaan dan situasi. Ketahui lebih lanjut mengenai jenis Algoritma termaju kami.',
    'page.body.landing.features.features.item3.text': 'Susun atur ruangan kerja mengikut keperluan anda: Kemaskini paparan kerja anda, pemilihan tema, tetapkan pemberitahuan dan pemilihan data.',
    'page.body.landing.features.features.item4.text': 'Keselamatan maklumat dan dana pengguna adalah keutamaan kami. Hubungi kami untuk mengetahui lebih lanjut mengenai ciri dan integrasi keselamatan kami.',
    'page.body.landing.features.features.item5.text': 'Sertailah komuniti global yang mempercayai kehebatan matawang digital.',
    'page.body.landing.features.features.item6.text': 'Perkhidmatan Websocket kami membolehkan anda memperoleh akses ke data pasaran sebenar dan terkini dengan mudah, sementara sistem API perdagangan kami membolehkan anda mengembangkan bot perdagangan yang selamat dan teratur.',

    'page.body.landing.tradeOnTheGo.item.title': 'Berdagang di mana sahaja',
    'page.body.landing.tradeOnTheGo.item.text1': 'Platform kami dioptimumkan untuk digunakan di mana-mana peranti elektronik.',
    'page.body.landing.tradeOnTheGo.item.text2': 'Dan anda tidak perlu memuat turun aplikasi tambahan.',
    'page.body.landing.tradeOnTheGo.item.text3': 'Dengan B4U Wallet semua kekuatan pertukaran matawang digital di tangan anda .',
    'page.body.landing.tradeOnTheGo.item.button': 'Jom Cuba',

    'page.body.landing.startTrading.title': 'Mula Berdagang Sekarang',
    'page.body.landing.startTrading.button1': 'Pendaftaran',
    'page.body.landing.startTrading.button2': 'Mula Berdagang',

    'page.body.landing.footer.exchange': 'Pertukaran',
    'page.body.landing.footer.wallets': 'Pelbagai Dompet',
    'page.body.landing.footer.fees': 'Pelbagai Yuran',
    'page.body.landing.footer.faq': 'FAQ',
    'page.body.landing.footer.support': 'Bantuan',
    'page.body.landing.footer.privacy': 'Privasi',
    'page.body.landing.footer.about': 'Berkaitan Kami',
    'page.body.landing.footer.community': 'Komuniti',
    'page.body.landing.footer.info': 'Maklumat',
    'page.body.landing.footer.rights': 'B4U Wallet & Exchange © Hak Cipta Terpelihara.',


    /* Wallets */

    'page.body.wallets.title': 'Wallets',
    'page.body.wallets.action.deposit': 'Kemasukkan',
    'page.body.wallets.action.withdraw': 'Pengeluaran',
    'page.body.wallets.action.transfer': 'Pemindahan',
    'page.body.wallets.action.trade': 'Berdagang',
    'page.body.wallets.action.buy': 'Beli',

    'page.body.wallets.estimated_value': 'Anggaran Nilai',
    'page.body.wallets.input.search.placeholder': 'Carian',
    'page.body.wallets.checkbox.label.hide_balance': 'Sembunyikan Baki Kecil',

    'page.body.wallets.table.header.coin': 'Syiling',
    'page.body.wallets.table.header.total': 'Jumlah',
    'page.body.wallets.table.header.available': 'Baki Tersisa',
    'page.body.wallets.table.header.locked': 'Dikunci',
    'page.body.wallets.table.header.actions': 'Pelbagai Tindakan',

    'page.body.wallets.locked': 'Dikunci',
    'page.body.wallets.balance': 'Baki',
    'page.body.wallets.tabs.deposit': 'Kemasukkan',
    'page.body.wallets.tabs.deposit.disabled.message': 'Kemasukkan tidak dibenarkan oleh pentadbir',
    'page.body.wallets.tabs.deposit.ccy.message.submit': 'Sila masukkan deposit menggunakan alamat dompet berikut atau guna kod QR. Deposit anda akan ditunjukkan dalam akaun anda setelah status {confirmations} tertera sebagai pengesahan',
    'page.body.wallets.tabs.deposit.ccy.message.address': 'Alamat Deposit',
    'page.body.wallets.tabs.deposit.ccy.message.button': 'SALIN',
    'page.body.wallets.tabs.deposit.ccy.message.success': 'Alamat disalin',
    'page.body.wallets.tabs.deposit.ccy.message.error': 'Penghasilan alamat deposit',
    'page.body.wallets.tabs.deposit.ccy.button.generate': 'Berhasil',
    'page.body.wallets.tabs.deposit.ccy.button.address': 'alamat',

    'page.body.wallets.tabs.deposit.fiat.message1': 'Deposit menggunakan pindahan wang melalui bank',
    'page.body.wallets.tabs.deposit.fiat.message2': 'Sila penuhi keperluan berikut untuk memulakan pindahan wang dari bank anda. Deposit anda akan dikreditkan dalam akaun anda dalam 2 hari bekerja.',
    'page.body.wallets.tabs.deposit.fiat.message3': 'Sila gunakan kod rujukan di bawah ini dalam rujukan pembayaran anda.',
    'page.body.wallets.tabs.deposit.fiat.bankName': 'Nama Bank',
    'page.body.wallets.tabs.deposit.fiat.accountNumber': 'Nombor Akaun',
    'page.body.wallets.tabs.deposit.fiat.accountName': 'Nama Akaun',
    'page.body.wallets.tabs.deposit.fiat.phoneNumber': 'Nombor Telefon',
    'page.body.wallets.tabs.deposit.fiat.referenceCode': 'Kod Penajaan Anda',
    'page.body.wallets.table.pending': 'Terbengkali',
    'page.body.wallets.table.rejected': 'Ditolak',
    'page.body.wallets.tabs.deposit.fiat.admin': ' Untuk memulakan pengeluaran fiat, sila hubungi pentadbir!',

    'page.body.wallets.tabs.withdraw': 'Pengeluaran',
    'page.body.wallets.tabs.convert': 'Beli & Jual',
    'page.body.wallets.tabs.withdraw.content.address': 'Alamat Pengeluaran',
    'page.body.wallets.tabs.withdraw.content.amount': 'Jumlah Pengeluaran',
    'page.body.wallets.tabs.withdraw.content.code2fa': 'Kod 2FA',
    'page.body.wallets.tabs.withdraw.content.fee': 'Upah',
    'page.body.wallets.tabs.withdraw.content.available': 'Jumlah Yang Ada',
    'page.body.wallets.tabs.withdraw.content.total': 'Jumlah Keseluruhan Pengeluaran',
    'page.body.wallets.tabs.withdraw.content.button': 'PENGELUARAN',
    'page.body.wallets.tabs.withdraw.disabled.message': 'Pengeluaran tidak dibenarkan oleh pentadbir',
    'page.body.wallets.tabs.withdraw.amount.error': 'Jumlah pengeluaran tidak boleh melebihi jumlah pengeluaran. (Jumlah pengeluaran termasuk bayaran pengeluaran yang {fee} {currency} setiap pengeluaran)',

    'page.body.wallets.tabs.withdraw.modal.confirmation': 'Pengesahan',
    'page.body.wallets.tabs.withdraw.modal.message1': 'Anda akan menerima ',
    'page.body.wallets.tabs.withdraw.modal.message2': ' pada alamat',
    'page.body.wallets.tabs.withdraw.modal.button.cancel': 'DIBATALKAN',
    'page.body.wallets.tabs.withdraw.modal.button.withdraw': 'Pengeluaran',

    'page.body.wallets.tabs.withdraw.content.enable2fa': 'Untuk proses pengeluaran anda perlu mengaktifkan 2FA',
    'page.body.wallets.tabs.withdraw.content.enable2faButton': 'Aktifkan 2FA',

    'success.beneficiaries.created': 'Waris: berjaya dibuat',
    'success.beneficiaries.activated': 'Waris: berjaya diaktifkan',
    'success.beneficiaries.deleted': 'Waris: berjaya dihapuskan',

    'page.body.wallets.beneficiaries.title': 'Alamat Pengeluaran',
    'page.body.wallets.beneficiaries.fiat.title': 'Maklumat Pengeluaran',
    'page.body.wallets.beneficiaries.dropdown.address': 'Alamat',
    'page.body.wallets.beneficiaries.dropdown.select': 'Pilihan',
    'page.body.wallets.beneficiaries.dropdown.name': 'Nama',

    'page.body.wallets.beneficiaries.dropdown.fiat.account': 'Akaun',
    'page.body.wallets.beneficiaries.dropdown.fiat.bankOfBeneficiary': 'Maklumat Bank',
    'page.body.wallets.beneficiaries.dropdown.fiat.beneficiary': 'Maklumat Lanjut',
    'page.body.wallets.beneficiaries.dropdown.fiat.description': 'Penerangan',
    'page.body.wallets.beneficiaries.dropdown.fiat.name': 'Nama',
    'page.body.wallets.beneficiaries.dropdown.fiat.fullName': 'Nama Penuh',

    'page.body.wallets.beneficiaries.addAddress': 'Tambah Alamat',
    'page.body.wallets.beneficiaries.addAddressModal.header': 'Tambah Alamat Pengeluaran Baru',

    'page.body.wallets.beneficiaries.addAddressModal.body.coinAddress': 'Alamat Blockchain',
    'page.body.wallets.beneficiaries.addAddressModal.body.coinBeneficiaryName': 'Nama Pemilik',
    'page.body.wallets.beneficiaries.addAddressModal.body.coinDescription': 'Penerangan (Tambahan)',

    'page.body.wallets.beneficiaries.addAddressModal.body.fiatName': 'Penerangan',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatFullName': 'Nama Penuh',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatAccountNumber': 'Nombor Akaun',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatBankName': 'Nama Bank',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatBankSwiftCode': 'Swift kod bank (Tambahan)',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatIntermediaryBankName': 'Nama Bank Pengantaraan (Tambahan)',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatIntermediaryBankSwiftCode': 'Swift kod bank pengantaraan  (tambahan)',


    'page.body.wallets.beneficiaries.addAddressModal.body.button': 'Hanatar Untuk Pengesahan',

    'page.body.wallets.beneficiaries.confirmationModal.header': 'Sila Sahkan Alamat Yang Baru',
    'page.body.wallets.beneficiaries.confirmationModal.body.text': 'Kami telah menghantar e-mel yang mengandungi pin kod pengesahan kepada anda, masukkan di bawah untuk mengemaskini alamat yang baru:',
    'page.body.wallets.beneficiaries.confirmationModal.body.confirmationModalCode': 'Pin Kod',
    'page.body.wallets.beneficiaries.confirmationModal.body.button': 'Telah Disahkan',

    'page.body.wallets.beneficiaries.tipAddress': 'Alamat',
    'page.body.wallets.beneficiaries.tipName': 'Nama',
    'page.body.wallets.beneficiaries.tipDescription': 'Nota',

    'page.body.wallets.beneficiaries.failAddModal.header': 'Amaran',
    'page.body.wallets.beneficiaries.failAddModal.content': 'Anda perlu mengesahkan akaun anda untuk menambahkan waris atau kemaskini maklumat',
    'page.body.wallets.beneficiaries.failAddModal.button': 'Pengesahan pada Akaun',

    'page.body.swap.title.swap': 'Pertukaran',
    'page.body.swap.title.buy_sell': 'Beli/Jual',
    'page.body.swap.available': 'Baki Yang Masi Ada',
    'page.body.swap.input.swap': 'Tukar',
    'page.body.swap.input.sell': 'Jual',
    'page.body.swap.input.buy': 'Beli',
    'page.body.swap.input.tag.max': 'Maks',
    'page.body.swap.input.error1': 'Jumlahnya mesti lebih tinggi daripada {amount}.',
    'page.body.swap.input.error2': 'Jumlahnya mesti lebih rendah daripada {amount}.',
    'page.body.swap.input.error3': 'Baki anda tidak mencukupi.',
    'page.body.swap.input.otp_code': 'Kod Otp',
    'page.body.swap.input.otp_code_error': 'Sila masukkan kod otp yang sah.',
    'page.body.swap.fee': 'Fee',
    'page.body.swap.price': 'Harga',
    'page.body.swap.receive': 'Penerimaan',
    'page.body.swap.you_will_get': 'Anda akan mendapat',
    'page.body.swap.button.text.swap': 'Tukar',
    'page.body.swap.button.text.buy': 'Beli',
    'page.body.swap.history.title.swap_history': 'Sejarah Pertukaran',
    'page.body.swap.history.title.buy_sell_history': 'Sejarah Jual/Beli',
    'page.body.swap.history.table.column.sell': 'Jual',
    'page.body.swap.history.table.column.amount': 'Jumlah',
    'page.body.swap.history.table.column.buy': 'Beli',
    'page.body.swap.history.table.column.status': 'Status',
    'page.body.swap.history.table.column.date': 'Tarikh',
    'page.body.swap.history.table.pagination.text.rows_per_page': 'Semak Setiap Halaman',

    'page.body.buy_crypto.title.buy_crypto': 'Beli Kripto',
    'page.body.buy_crypto.content1': 'Beli Bitcoin and pelbagai matawang kripto lain dengan mudah',
    'page.body.buy_crypto.content2': 'Beli dan melabur dalam beberapa minit menggunakan kad kredit anda, pindahan wang melalui bank atau Apple Pay.',
    'page.body.buy_crypto.fields.buy': 'Saya mahu beli',
    'page.body.buy_crypto.fields.spend': 'Saya mahu dapatkannya',
    'page.body.buy_crypto.fields.how_much': 'Dengan sebanyak ini',
    'page.body.buy_crypto.submit_button.text': 'Beli {currencyCode}',
    'page.body.buy_crypto.modal.exit_button.text': 'Keluar',
    'page.body.buy_crypto.field.error1': 'Jumlah tidak mencukupi. Had minimum ialah {amount} {currencyCode}',
    'page.body.buy_crypto.field.error2': 'Melebihi Had. Had maksimum bagi setiap transaksi ialah {amount} {currencyCode}',

    'page.body.openOrders.tab.all': 'KESELURUHAN',
    'page.body.openOrders.tab.open': 'Buka',
    'page.body.openOrders.header.orderType': 'Jenis Pesanan',
    'page.body.openOrders.header.orderType.buy.market': 'Pasaran Belian',
    'page.body.openOrders.header.orderType.buy.limit': 'Had Belian',
    'page.body.openOrders.header.orderType.sell.market': 'Pasaran Jualan',
    'page.body.openOrders.header.orderType.sell.limit': 'Had Jualan',
    'page.body.openOrders.header.pair': 'Pair',
    'page.body.openOrders.header.amount': 'Jumlah',
    'page.body.openOrders.header.price': 'Harga',
    'page.body.openOrders.header.executed': 'Telah Berjaya',
    'page.body.openOrders.header.remaining': 'Selebihnya',
    'page.body.openOrders.header.costRemaining': 'Kos selebihnya',
    'page.body.openOrders.header.status': 'Status',
    'page.body.openOrders.content.status.done': 'Telah Berjaya',
    'page.body.openOrders.content.status.wait': 'Buka',
    'page.body.openOrders.content.status.cancel': 'Dihentikan',
    'page.body.openOrders.header.button.cancelAll': 'Batalkan Semua',

    'page.body.history.deposit': 'Sejarah Deposit',
    'page.body.history.deposit.header.txid': 'txID',
    'page.body.history.deposit.header.date': 'Tarikh',
    'page.body.history.deposit.header.currency': 'Mata Wang',
    'page.body.history.deposit.header.amount': 'Jumlah',
    'page.body.history.deposit.header.status': 'Status',
    'page.body.history.deposit.content.status.accepted': 'Diterima',
    'page.body.history.deposit.content.status.collected': 'Dikumpulkan',
    'page.body.history.deposit.content.status.submitted': 'Dihantar',
    'page.body.history.deposit.content.status.canceled': 'Dibatalkan',
    'page.body.history.deposit.content.status.rejected': 'Ditolak',
    'page.body.history.deposit.content.status.skipped': 'Dilangkau',
    'page.body.history.deposit.content.status.succeed': 'Berjaya',

    'page.body.history.withdraw': 'Sejarah Pengeluaran',
    'page.body.history.withdraw.header.id': 'ID',
    'page.body.history.withdraw.header.date': 'Tarikh',
    'page.body.history.withdraw.header.currency': 'Mata Wang',
    'page.body.history.withdraw.header.address': 'Alamat',
    'page.body.history.withdraw.header.amount': 'Jumlah',
    'page.body.history.withdraw.header.fee': 'Fee',
    'page.body.history.withdraw.header.status': 'Status',
    'page.body.history.withdraw.header.txid': 'txID',
    'page.body.history.withdraw.content.status.prepared': 'Disediakan',
    'page.body.history.withdraw.content.status.submitted': 'Dihantar',
    'page.body.history.withdraw.content.status.skipped': 'Dilangkau',
    'page.body.history.withdraw.content.status.canceled': 'Dibatalkan',
    'page.body.history.withdraw.content.status.accepted': 'Diterima',
    'page.body.history.withdraw.content.status.suspected': 'Disyakki',
    'page.body.history.withdraw.content.status.rejected': 'Ditolak',
    'page.body.history.withdraw.content.status.processing': 'Memproses',
    'page.body.history.withdraw.content.status.succeed': 'Berjaya',
    'page.body.history.withdraw.content.status.failed': 'Gagal',
    'page.body.history.withdraw.content.status.confirming': 'Mengesahkan',
    'page.body.history.withdraw.content.status.errored': 'Ralat',
    'page.body.history.withdraw.content.status.collected': 'Dikumpulkan',

    'page.body.history.trade': 'Sejarah Berdagang',
    'page.body.history.trade.header.id': 'ID',
    'page.body.history.trade.header.date': 'Tarikh',
    'page.body.history.trade.header.side': 'Sebelah',
    'page.body.history.trade.content.side.buy': 'Beli',
    'page.body.history.trade.content.side.sell': 'Jual',
    'page.body.history.trade.header.market': 'Pasaran',
    'page.body.history.trade.header.price': 'Harga',
    'page.body.history.trade.header.total': 'Jumlah Keseluruhan',
    'page.body.history.trade.header.amount': 'Jumlah',
    'page.body.history.trade.header.balance': 'Baki',

    // Deposit Screen
    'page.body.deposit.header.title': 'Deposit',
    'page.body.deposit.tabs.crypto': 'Kripto',
    'page.body.deposit.tabs.fiat': 'Fiat',
    'page.body.deposit.select.title': 'Carian Mata Wang',
    'page.body.deposit.total_balance': 'Baki Keseluruhan',
    'page.body.deposit.tips.title': 'Pelbagai Cadangan',
    'page.body.deposit.tips.tip1': 'Sekiranya anda telah membuat deposit, sila semak pesanan teks, maklumat di laman web dan e-mel yang kami kirimkan kepada anda.',
    'page.body.deposit.tips.tip2': 'Selagi {confirmations} pengesahan belum dibuat, jumlah aset anda yang terkini tidak akan dipaparkan buat sementara waktu untuk pengeluaran.',
    'page.body.deposit.network.title': 'Deposit rangkaian',
    'page.body.deposit.network.message': 'Sila pilih format alamat Deposit B4U yang sesuai mengikut jenis coin atau token yang dipindahkan. Perhatikan bahawa beberapa dompet mungkin menyokong pelbagai jenis format alamat dompet umum, seperti dari jenis USDT, ERC20, OMNI, dan TRC20. Pastikan bahawa jenis format alamat dompet yang dipilih adalah bersesuaian dan menyokong format dompet untuk Deposit di B4U Wallet.',
    'page.body.deposit.network.address.text': '{currency} Alamat.',
    'page.body.deposit.network.tag.text': 'Tag {currency}',
    'page.body.deposit.network.tag.instruction': 'Sila masukkan kedua-dua data Tag dan Alamat, yang diperlukan untuk berjaya memasukkan {currency} ke akaun B4U anda.',
    'page.body.deposit.network.address.instructions.title': 'Sila hantar {currency} kepada alamat dompet ini..',
    'page.body.deposit.network.address.instructions.description': 'Penghantaran coin atau token {currency} selain dari ke alamat ini boleh menyebabkan kehilangan deposit anda..',

    'page.body.withdraw.header.title': 'Pengeluaran',
    'page.body.withdraw.tabs.crypto': 'Kripto',
    'page.body.withdraw.tabs.fiat': 'Fiat',
    'page.body.withdraw.select.title': 'Carian Mata Wang',
    'page.body.withdraw.total_balance': 'Baki Keseluruhan',
    'page.body.withdraw.tips.title': 'Pelbagai Cadangan',
    'page.body.withdraw.tips.tip1': 'Jangan buat pengeluaran kepada crowdfund atau alamat ICO, kerana akaun anda tidak akan dikreditkan dengan token dari penjualan tersebut.',
    'page.body.withdraw.tips.tip2': "Semasa proses pengeluaran ke alamat pengguna B4U, bayaran pengendalian akan dikembalikan ke Akaun Semasa secara Default.",

    'page.body.profile.header.account': 'Profil',

    'page.body.profile.title': 'Pengurusan Profil',
    'page.body.profile.tabs.security': 'Profil / Keselamatan',
    'page.body.profile.tabs.identification': 'Pengenalan',
    'page.body.profile.tabs.referral': 'Rujukan',
    'page.body.profile.tabs.api_management': 'Pengurusan API',
    'page.body.profile.tabs.activity': 'Aktiviti',

    'page.body.profile.tabs.referral.title': 'Jemput Rakan & Dapatkan Crypto Bersama',
    'page.body.profile.tabs.referral.description': 'DAPATKAN SEHINGGA 40% KOMISEN SETIAP MASA RAKAN ANDA MEMBUAT PERDAGANGAN DI B4U.',
    'page.body.profile.tabs.referral.instruction.title': 'Jemput Sekarang',
    'page.body.profile.tabs.referral.instruction.description': 'Gunakan pautan unik anda untuk menjemput rakan anda melalui mesej atau e-mel. Kod jemputan lalai anda juga dapat dikongsi dalam kehidupan nyata atau sebagai tangkapan skrin.',

    'page.body.profile.tabs.referral.id': 'ID Rujukan',
    'page.body.profile.tabs.referral.link': 'Pautan Rujukan',

    'page.body.profile.header.account.content.password': 'Kata Laluan',
    'page.body.profile.header.account.content.password.button.change': 'Ubah Kata Laluan',
    'page.body.profile.header.account.content.password.old': 'Kata Lalauan Lama',
    'page.body.profile.header.account.content.password.new': 'Kata Laluan Baru',
    'page.body.profile.header.account.content.password.button.save': 'Simpan',
    'page.body.profile.header.account.content.password.button.cancel': 'Batalkan',
    'page.body.profile.header.account.content.password.conf': 'Pengesahan kata laluan',
    'page.body.profile.header.account.content.password.dont.match': 'Kata laluan tidak sepadan',
    'page.body.profile.header.account.content.password.change.success': 'Berjaya!',
    'page.body.profile.header.account.content.password.change': 'Ubah Kata Lauan',

    'page.body.profile.header.account.content.twoFactorAuthentication': '2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.enable': 'Diaktifkan',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.disable': 'Dinyahaktifkan',
    'page.body.profile.header.account.content.twoFactorAuthentication.header': 'Google Authenticator',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.1': 'Muat Turun and pasangkan aplikasi Google Authenticator dari',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.or': 'atau ',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.2': 'Scan kod QR or guna kod rahsia MFA :',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.3': '* Simpan kod rahsia ini di lokasi yang selamat. Kod ini dapat digunakan untuk mendapatkan akses 2FA dari peranti lain',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.mfa': 'Kod MFA',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.4': 'Masukkan kod 2FA dari aplikasi',
    'page.body.profile.header.account.content.twoFactorAuthentication.subHeader': 'Kod 2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.enable': 'DIAKTIFKAN',
    'page.body.profile.header.account.content.twoFactorAuthentication.disable': 'DINYAHAKTIFKAN 2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.modalBody': 'Sila hubungi pentadbir untuk menyahaktifkannya',
    'page.body.profile.header.account.content.twoFactorAuthentication.modalHeader': 'Pengesahan dua faktor (2FA) diaktifkan',

    'page.body.profile.header.account.profile': 'Pengesahan Profil',
    'page.body.profile.header.account.profile.email.title': 'Pengesahan E-mel',
    'page.body.profile.header.account.profile.email.message': 'Deposit/Pengeluaran dibenarkan',
    'page.body.profile.header.account.profile.phone.unverified.title': 'Pengesahan Telefon',
    'page.body.profile.header.account.profile.phone.title': 'Telefon disahkan',
    'page.body.profile.header.account.profile.phone.message': 'Deposit/Berdagang dibenarkan',
    'page.body.profile.header.account.profile.identity.unverified.title': 'Pengesahan Identiti',
    'page.body.profile.header.account.profile.identity.title': 'Identiti Disahkan',
    'page.body.profile.header.account.profile.identity.message': 'Pengeluaran dibenarkan',

    'page.body.profile.header.referralProgram': 'Link Penajaan',
    'page.body.profile.content.copyLink': 'Salin',

    'page.body.profile.apiKeys.header': 'Kunci API Anda',
    'page.body.profile.apiKeys.header.create': 'Buat Baru',

    'page.body.profile.apiKeys.noOtp': 'Sila aktifkan pengesahan Dua faktor',
    'page.body.profile.apiKeys.show': 'Tunjukkan',
    'page.body.profile.apiKeys.noKeys': 'Saya tiada sebarang kunci API',

    'page.body.profile.apiKeys.modal.btn.show': 'Tunjukkan',
    'page.body.profile.apiKeys.modal.btn.create': 'Disahkan',
    'page.body.profile.apiKeys.modal.btn.copy': 'Salin',
    'page.body.profile.apiKeys.modal.btn.activate': 'Diaktifkan',
    'page.body.profile.apiKeys.modal.btn.disabled': 'Dinyahaktifkan',
    'page.body.profile.apiKeys.modal.btn.delete': 'Dipadam',
    'page.body.profile.apiKeys.modal.header': 'Pengesahan 2FA',
    'page.body.profile.apiKeys.modal.created_header': 'Dibina',
    'page.body.profile.apiKeys.modal.access_key': 'Kunci Akses',
    'page.body.profile.apiKeys.modal.secret_key': 'Kunci Rahsia',
    'page.body.profile.apiKeys.modal.secret_key_info': 'Maklumat ini akan ditunjukkan hanya sekali sahaja dan tidak boleh diulang siar sekiranya hilang.',
    'page.body.profile.apiKeys.modal.secret_key_store': 'Sila simpan dengan betul dan selamat.',
    'page.body.profile.apiKeys.modal.note': 'Note',
    'page.body.profile.apiKeys.modal.note_content': `Untuk mengelakkan kehilangan aset, jangan beritahu Kunci Rahsia dan Kunci Peribadi anda kepada orang lain.\
Sekiranya anda terlupa Kunci Rahsia, sila hapuskan yang lama dan mohon semula Kunci Rahsia yang baru.`,
    'page.body.profile.apiKeys.modal.title': 'Masukkan kod 2fa dari aplikasi',
    'page.body.profile.apiKeys.modal.label': 'Sila masukkan 6 digit Kod Pengesah Google Authenticator',
    'page.body.profile.apiKeys.modal.placeholder': 'Masukkan Kod',

    'page.body.profile.apiKeys.table.header.kid': 'Kid',
    'page.body.profile.apiKeys.table.header.algorithm': 'Algoritma',
    'page.body.profile.apiKeys.table.header.state': 'Penyata',
    'page.body.profile.apiKeys.table.header.created': 'Dibina',
    'page.body.profile.apiKeys.table.header.updated': 'Dikemaskini',

    'success.api_keys.fetched': 'Berjaya mengambil kunci API',
    'success.api_keys.created': 'Kunci API telah dibuat',
    'success.api_keys.copied.access': 'Kunci akses disalin',
    'success.api_keys.copied.secret': 'Kunci rahsia disalin',
    'success.api_keys.updated': 'Kunci API telah dikemas kini',
    'success.api_keys.deleted': 'Kunci API telah dipadamkan',

    'page.body.profile.header.accountActivity': 'Aktiviti Akaun',
    'page.body.profile.header.accountActivity.content.date': 'Tarikh',
    'page.body.profile.header.accountActivity.content.addressip': 'Alamat IP',
    'page.body.profile.header.accountActivity.content.action': 'Tindakan',
    'page.body.profile.header.accountActivity.content.result': 'Keputusan',
    'page.body.profile.header.accountActivity.content.userAgent': 'Ejen Pengguna',

    'page.body.profile.content.action.login': 'Log Masuk',
    'page.body.profile.content.action.logout': 'Log Keluar',
    'page.body.profile.content.action.request2fa': 'Permintaan kod QR untuk 2FA',
    'page.body.profile.content.action.enable2fa': 'Aktifkan 2FA',
    'page.body.profile.content.action.login.2fa': 'Log Masuk dengan 2FA',
    'page.body.profile.content.action.requestPasswordReset': 'Minta tetapkan semula kata laluan',
    'page.body.profile.content.action.passwordReset': 'Tetapan Semula Kata Laluan',

    'page.body.profile.content.result.succeed': 'Berjaya',
    'page.body.profile.content.result.failed': 'Gagal',
    'page.body.profile.content.result.denied': 'Ditolak',

    'page.body.kyc.phone.head': 'Sahkan Telefon',
    'page.body.kyc.phone.enterPhone': 'Masukkan nombor telefon',
    'page.body.kyc.phone.phoneNumber': 'Nombor telefon',
    'page.body.kyc.phone.enterCode': 'Masukkan kod pengesahan',
    'page.body.kyc.phone.code': 'Kod SMS',
    'page.body.kyc.phone.send': 'HANTAR KOD',
    'page.body.kyc.phone.resend': 'HANTAR semula KOD',
    'page.body.kyc.identity.firstName': 'Nama pertama',
    'page.body.kyc.identity.lastName': 'Nama terakhir',
    'page.body.kyc.identity.dateOfBirth': 'Tarikh Lahir',
    'page.body.kyc.identity.residentialAddress': 'Alamat kediaman',
    'page.body.kyc.identity.city': 'Bandar',
    'page.body.kyc.identity.postcode': 'Poskod',
    'page.body.kyc.identity.nationality': 'Kewarganegaraan',
    'page.body.kyc.identity.CoR': 'Negara Tempat Tinggal',
    'page.body.kyc.documents.expiryDate': 'Tarikh Luput',
    'page.body.kyc.documents.drag': 'Seret dan lepas atau semak fail',
    'page.body.kyc.documents.maxFile': 'Saiz fail maksimum ialah 10MB',
    'page.body.kyc.documents.maxNum': 'Bilangan fail maksimum ialah 5',
    'page.body.kyc.documents.upload': 'Muat naik ID Foto anda',
    'page.body.kyc.documents.select.passport': 'Pasport',
    'page.body.kyc.documents.select.identityCard': 'Kad pengenalan diri',
    'page.body.kyc.documents.select.driverLicense': 'Lesen memandu',
    'page.body.kyc.documents.select.utilityBill': 'Bil utiliti',
    'page.body.kyc.documents.number': ': Nombor',
    'page.body.kyc.documentsType': 'Jenis dokumen',

    'page.body.kyc.next': 'Seterusnya',
    'page.body.kyc.submit': 'Hantar',
    'page.body.kyc.head.phone': 'Pengesahan Telefon',
    'page.body.kyc.head.identity': 'Pengesahan Identiti',
    'page.body.kyc.head.document': 'Pengesahan Dokumen',

    'page.body.lock.oops': 'Alamak!',
    'page.body.lock.expired': 'Nampaknya percubaan anda telah tamat',
    'page.body.lock.license': 'Nampaknya kunci lesen anda tidak sah',
    'page.body.lock.visit': 'Lawati',

    'page.footer.legalDocuments': 'Dokumen undang-undang',
    'page.footer.faq': 'Soalan Lazim',

    'page.header.signIn': 'Log masuk',
    'page.header.signIn.email': 'E-mel',
    'page.header.signIn.password': 'Kata Laluan',
    'page.header.signIn.password.message.error': 'Kata laluan tidak sah',
    'page.header.signIn.receiveConfirmation': 'Tidak menerima e-mel pengesahan anda?',
    'page.header.signIn.forgotPassword': 'Lupa kata laluan anda?',
    'page.header.signIn.resetPassword.title': 'Menetapkan semula kata laluan',
    'page.header.signIn.resetPassword.newPassword': 'Kata laluan baharu',
    'page.header.signIn.resetPassword.repeatPassword': 'Ulang kata laluan',
    'page.header.signIn.resetPassword.button': 'Ubah',
    'page.header.signIn.resetPassword.error': 'Medan kosong atau tidak sepadan',

    'page.header.signUp': 'Mendaftar',
    'page.header.signUp.email': 'E-mel',
    'page.header.signUp.email.message.error': 'Email tidak sah',
    'page.header.signUp.password': 'Kata Laluan',
    'page.header.signUp.password.message.error': 'Kata laluan mesti mengandungi sekurang-kurangnya 8 aksara, sekurang-kurangnya satu huruf besar dan satu digit',
    'page.header.signUp.confirmPassword': 'Sahkan Kata Laluan',
    'page.header.signUp.confirmPassword.message.error': 'Kata laluan tidak sepadan',
    'page.header.signUp.referalCode': 'Kod rujukan',
    'page.header.signUp.terms': 'Saya telah membaca dan bersetuju dengan Syarat Perkhidmatan',
    'page.header.signUp.modal.header': 'MENGESAHKAN ALAMAT E-MEL ANDA',
    'page.header.signUp.modal.body': 'To complete registration, check for an ' +
                                      'email in your inbox with further ' +
                                      'instruction. If you cannot find the email, ' +
                                      'please check your spam folder',
    'page.header.signUp.modal.footer': 'okey',
    'page.header.signUp.strength.password': 'Kekuatan Kata Laluan',
    'page.header.signUp.password.too.weak': 'TERLALU LEMAH',
    'page.header.signUp.password.weak': 'Lemah',
    'page.header.signUp.password.good': 'BAIK',
    'page.header.signUp.password.strong': 'KUAT',
    'page.header.signUp.password.very.strong': 'SANGAT KUAT',
    'page.resendConfirmation': 'Hantar semula Pengesahan',
    'page.forgotPassword': 'Lupa kata laluan',
    'page.forgotPassword.message': 'Masukkan e-mel untuk menetapkan semula kata laluan',
    'page.password2fa': 'Pengesahan 2FA',
    'page.password2fa.message': 'Masukkan kod 2fa dari aplikasi',
    'page.forgotPassword.email': 'E-mel',
    'page.forgotPassword.send': 'Hantar',
    'page.noDataToShow': 'Tidak ada data untuk ditunjukkan',

    'page.modal.withdraw.success': 'Berjaya!',
    'page.modal.withdraw.success.message.content': 'Permintaan pengeluaran anda telah diterima',
    'page.modal.withdraw.success.button': 'okey',

    'page.modal.expired.title': 'Sesi anda telah tamat',
    'page.modal.expired.submit': 'Log masuk sekali lagi',


    /* Customization */
    'page.body.customization.tabs.themes': 'Warna',
    'page.body.customization.tabs.fonts': 'Fon',
    'page.body.customization.tabs.spacing': 'Jarak',
    'page.body.customization.tabs.images': 'Gambar',
    'page.body.customization.comingSoon': 'Akan datang',
    'page.body.customization.actionButtons.reset': 'Tetapkan semula',
    'page.body.customization.actionButtons.save': 'Jimat',

    'page.body.customization.themes.selector.label': 'Pratetap Tema',

    'page.body.customization.themes.color.mainBackgroundColor': 'Latar belakang utama',
    'page.body.customization.themes.color.bodyBackgroundColor': 'Latar belakang badan',
    'page.body.customization.themes.color.headerBackgroundColor': 'Latar belakang tajuk',
    'page.body.customization.themes.color.subheaderBackgroundColor': 'Latar belakang subheader',
    'page.body.customization.themes.color.dropdownBackgroundColor': 'Latar belakang dropdown',
    'page.body.customization.themes.color.icon': 'Ikon',
    'page.body.customization.themes.color.primaryCtaColor': 'Ajakan bertindak yang utama',
    'page.body.customization.themes.color.contrastCtaColor': 'Seruan Bertindak Kontras',
    'page.body.customization.themes.color.secondaryContrastCtaColor': 'CTA Kontras Sekunder',
    'page.body.customization.themes.color.ctaLayerColor': 'Lapisan CTA',
    'page.body.customization.themes.color.systemGreen': 'Sistem Hijau',
    'page.body.customization.themes.color.systemRed': 'Sistem Merah',
    'page.body.customization.themes.color.systemYellow': 'Sistem Kuning',
    'page.body.customization.themes.color.asks': 'Bertanya warna',
    'page.body.customization.themes.color.bids': 'Warna bidaan',
    'page.body.customization.themes.color.primaryTextColor': 'Teks utama',
    'page.body.customization.themes.color.textContrastColor': 'Kontras teks',
    'page.body.customization.themes.color.inputBackgroundColor': 'Latar belakang input',
    'page.body.customization.themes.color.dividerColor': 'Warna pembahagi',
    'page.body.customization.themes.color.shadowColor': 'Warna bayangan',
    'page.body.customization.themes.color.landingBackgroundColor': 'Latar belakang pendaratan',
    'page.body.customization.themes.color.strengthMeterVeryStrong': 'Kata laluan sangat kuat',

    'page.body.customization.themes.theme.darkBlue.title': 'Biru gelap',
    'page.body.customization.themes.theme.darkRed.title': 'Merah gelap',
    'page.body.customization.themes.theme.purple.title': 'Ungu',
    'page.body.customization.themes.theme.green.title': 'Hijau',


    // success messages
    'success.documents.accepted': 'Muat naik dokumen berjaya',
    'success.identity.accepted': 'Muat naik identiti berjaya',
    'success.withdraw.action': 'Permintaan pengeluaran telah diterima',
    'success.otp.enabled': '2FA diaktifkan',
    'success.otp.disabled': '2FA dilumpuhkan',
    'success.password.changed': 'Kata laluan ditukar',
    'success.password.forgot': 'Pautan penetapan semula kata laluan telah dihantar ke e-mel anda',
    'success.password.changed.successfuly': 'Kata laluan ditukar',
    'success.order.cancelling': 'Pesanan dibatalkan',
    'success.order.canceled': 'Pesanan dibatalkan',
    'success.order.canceled.all': 'Semua pesanan dibatalkan',
    'success.order.cancelling.all': 'Semua pesanan dibatalkan',
    'success.phone.verification.send': 'Kod pengesahan telah dihantar ke telefon anda',
    'success.phone.confirmed': 'Telefon anda telah disahkan',
    'success.phone.confirmation.message': 'Berjaya!',
    'success.message.sent': 'Mesej telah dihantar',
    'success.email.confirmed': 'Alamat e-mel anda telah berjaya disahkan',
    'success.order.created': 'Pesanan dibuat',
    'success.order.done': 'Pesanan berjaya diselesaikan',
    'success.data.changed.language': 'Bahasa telah diubah',

    // error messages
    'error.order.rejected': 'Pesanan ditolak',
    'error.invalid_request': 'Pesanan - permintaan tidak sah',
    'error.bad_request': 'Pesanan - permintaan buruk',
    'error.request_entity_too_large': 'Pesanan - entiti permintaan terlalu besar',

    // barong
    'resource.labels.private': 'Tidak dapat mengemas kini label',
    'resource.user.no_activity': 'Tiada aktiviti yang direkodkan atau topik yang salah',
    'resource.profile.not_exist': 'Pengguna tidak mempunyai profil',
    'resource.profile.exist': 'Profil sudah ada',
    'resource.api_key.2fa_disabled': 'Hanya akaun dengan 2FA yang diizinkan dibenarkan',
    'resource.api_key.missing_otp': 'Akaun telah mengaktifkan 2FA tetapi kod OTP tiada',
    'resource.api_key.invalid_otp': 'Kod OTP tidak sah',
    'resource.phone.twillio': 'Ada yang tidak kena dengan Pelanggan Twilio',
    'resource.phone.invalid_num': 'Nombor telefon tidak sah',
    'resource.phone.exists': 'Nombor telefon tidak disahkan. Kod pengesahan dihantar semula.',
    'resource.phone.number_exist': 'Nombor telefon sudah ada',
    'resource.phone.verification_invalid': 'Telefon tidak dijumpai atau kod pengesahan tidak sah',
    'resource.documents.limit_reached': 'Jumlah maksimum dokumen sudah dicapai',
    'resource.documents.limit_will_be_reached': 'Jumlah dokumen akan mencapai had dengan memuat naik ini',
    'resource.otp.already_enabled':'2FA telah diaktifkan untuk akaun ini',
    'resource.otp.invalid': 'Kod OTP tidak sah',
    'resource.password.doesnt_match': 'Kata laluan baru tidak sepadan',
    'resource.password.prev_pass_not_correct': 'Kata laluan sebelumnya tidak betul',
    'resource.password.no_change_provided': 'Kata laluan baru tidak boleh sama, seperti kata laluan lama',
    'resource.document.empty_doc_expire': 'Tarikh Luput tidak sah',
    'password.requirements': 'Kata laluan tidak memenuhi syarat minimum',
    'password.password.password_strength': 'Kata laluan terlalu lemah',

    'email.taken': 'E-mel sudah diambil',

    'identity.user.invalid_referral_format': 'Format rujukan uid tidak sah',
    'identity.user.referral_doesnt_exist': 'Rujukan tidak ada',
    'identity.user.active_or_doesnt_exist': 'Pengguna tidak wujud atau sudah diaktifkan',
    'identity.password.user_doesnt_exist': 'Pengguna tidak wujud',
    'identity.user.passwords_doesnt_match': 'Kata laluan tidak sepadan',
    'identity.user.utilized_token': 'JWT telah digunakan',
    'identity.session.invalid_login_params': 'Emel dan kata laluan tidak sah',
    'identity.session.invalid': 'Sesi tidak sah',
    'identity.captcha.required': 'captcha_response diperlukan',
    'identity.captcha.mandatory_fields': 'Bidang wajib mesti diisi',
    'identity.session.not_active': 'Akaun anda tidak aktif',
    'identity.session.banned': 'Akaun anda dilarang',
    'identity.session.invalid_params': 'Emel dan kata laluan tidak sah',
    'identity.session.missing_otp': 'Akaun telah mengaktifkan 2FA tetapi kod OTP tiada',
    'identity.session.invalid_otp': 'Kod OTP tidak sah',

    'first_name.invalid': 'Nama depan tidak sah',
    'last_name.invalid': 'Nama belakang tidak sah',
    'city.invalid': 'Bandar tidak sah',
    'postcode.invalid': 'Poskod tidak sah',
    'address.invalid': 'Alamat tidak sah',
    'first_name.blank': 'Nama depan tiada atau kosong',
    'last_name.blank': 'Nama belakang tiada atau kosong',
    'dob.blank': 'Tarikh lahir tidak sah',
    'address.blank': 'Alamat tiada atau kosong',
    'city.blank': 'Bandar hilang atau kosong',
    'country.blank': 'Negara tiada atau kosong',
    'postcode.blank': 'Poskod.blank tiada atau kosong',
    'country.must have alpha2 or alpha3 format': 'Negara mesti mempunyai format alpha2 atau alpha3',

    'totp.error': 'Kod OTP tidak sah',

    'record.not_found': 'Rekod tidak dijumpai',
    'jwt.decode_and_verify': 'Gagal menyahkod dan mengesahkan JWT',
    'authz.invalid_session': 'Gagal menyahkod kuki',
    'authz.user_not_active': 'Pengguna tidak aktif',
    'authz.invalid_signature': 'Header Kunci API \' tandatangan \' tidak sah',
    'authz.apikey_not_active': 'Keadaan Kunci API tidak aktif',
    'authz.disabled_2fa': 'Pemilik Kunci API telah melumpuhkan 2FA',
    'authz.invalid_api_key_headers': 'Pengepala Kunci API kosong atau hilang',
    'authz.permission_denied': 'Laluan disenarai hitam',
    'authz.unexistent_apikey': 'Tajuk X-Auth-Apikey tidak sah',
    'authz.client_session_mismatch': 'Kesalahan sesi',
    'authz.csrf_token_mismatch': 'Ketidakcocokan token CSRF',

    // validation errors
    // identity module
    'identity.user.missing_email': 'E-mel tiada',
    'identity.user.empty_email': 'E-mel tiada atau kosong',
    'identity.user.missing_password': 'Kata laluan tiada',
    'identity.user.empty_password': 'Kata laluan tiada atau kosong',
    'identity.user.missing_token': 'Token tiada',
    'identity.user.empty_token': 'Token tiada atau kosong',
    'identity.user.missing_reset_password_token': 'Token kata laluan set semula tiada',
    'identity.user.empty_reset_password_token': 'Token kata laluan yang ditetapkan semula tiada atau kosong',
    'identity.user.missing_confirm_password': 'Sahkan kata laluan tiada',
    'identity.user.empty_confirm_password': 'Sahkan kata laluan tiada atau kosong',

    'identity.session.missing_emai': 'E-mel tiada',
    'identity.session.missing_password': 'Kata laluan tiada',
    'identity.session.invalid_captcha_format': 'Format captcha tidak sah',

    // resource module
    'resource.otp.missing_code': 'Kod OTP tiada',
    'resource.otp.empty_code': 'Kod OTP tiada atau kosong',

    'resource.labels.missing_key': 'Kunci tiada',
    'resource.labels.empty_key': 'Kunci tiada atau kosong',
    'resource.labels.missing_value': 'Nilai tiada',
    'resource.labels.empty_value': 'Nilai tiada atau kosong',

    'resource.documents.missing_doc_expire': 'Tarikh luput dokumen tiada',
    'resource.documents.empty_doc_expire': 'Tarikh luput dokumen tiada atau kosong',
    'resource.documents.missing_doc_type': 'Jenis dokumen tiada',
    'resource.documents.empty_doc_type': 'Jenis dokumen tiada atau kosong',
    'resource.documents.missing_doc_number': 'Nombor dokumen tiada',
    'resource.documents.empty_doc_number': 'Nombor dokumen tiada atau kosong',
    'resource.documents.missing_upload': 'Lampiran tiada',


    'resource.user.missing_topic': 'Topik tiada',
    'resource.user.empty_topic': 'Topik tiada atau kosong',
    'resource.user.missing_old_password': 'Kata laluan lama tiada',
    'resource.user.empty_old_password': 'Kata laluan lama tiada atau kosong',
    'resource.user.missing_new_password': 'Kata laluan baru tiada',
    'resource.user.empty_new_password': 'Kata laluan baru tiada atau kosong',
    'resource.user.missing_confirm_password': 'Sahkan kata laluan tiada',
    'resource.user.empty_confirm_password': 'Sahkan kata laluan tiada atau kosong',

    'resource.profile.missing_first_name': 'Nama pertama tiada',
    'resource.profile.missing_last_name': 'Nama belakang tiada',
    'resource.profile.missing_dob': 'Tarikh lahir tiada',
    'resource.profile.missing_address': 'Alamat tiada',
    'resource.profile.missing_postcode': 'Poskod tiada',
    'resource.profile.missing_city': 'Bandar hilang',
    'resource.profile.missing_country': 'Negara hilang',

    'resource.api_key.missing_algorithm': 'Algoritma tiada',
    'resource.api_key.empty_algorithm': 'Algoritma tiada atau kosong',
    'resource.api_key.empty_kid': 'KID tiada atau kosong',
    'resource.api_key.empty_scope': 'Skop tiada atau kosong',
    'resource.api_key.missing_totp': 'Kod TOTP tiada',
    'resource.api_key.empty_totp': 'Kod TOTP tiada atau kosong',
    'resource.api_key.missing_kid': 'KID tiada',
    'resource.api_key.empty_state': 'Negeri tiada atau kosong',

    'resource.phone.missing_phone_number': 'Nombor telefon tiada',
    'resource.phone.empty_phone_number': 'Nombor telefon tiada atau kosong',
    'resource.phone.missing_verification_code': 'Kod pengesahan tiada',
    'resource.phone.empty_verification_code': 'Kod pengesahan tiada atau kosong',

    // peatio
    'account.currency.doesnt_exist': 'Mata wang tidak wujud',
    'account.deposit.invalid_state': 'Keadaan tidak sah deposit',
    'account.deposit.non_integer_limit': 'Nilai yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'account.deposit.invalid_limit': 'Had tidak sah',
    'account.deposit.non_positive_page': 'Nilai halaman mesti positif',
    'account.deposit.empty_txid': 'Txid tiada, txid kosong',
    'account.deposit_address.invalid_address_format': 'Format alamat deposit tidak sah',
    'account.deposit_address.doesnt_support_cash_address_format': 'Mata wang tidak menyokong format alamat tunai',
    'account.withdraw.non_integer_limit': 'Nilai Had yang anda hantar tidak dapat diuraikan ke jenis Integer',
    'account.withdraw.invalid_limit': 'Had tidak sah',
    'account.withdraw.non_positive_page': 'Nilai halaman mesti positif',
    'account.withdraw.non_integer_otp': 'Nilai Otp tidak dapat diuraikan ke jenis Integer',
    'account.withdraw.empty_otp': 'Otp tiada, otp kosong',
    'account.withdraw.empty_rid': 'Rid hilang, rid kosong',
    'account.withdraw.non_decimal_amount': 'Nilai jumlah yang anda hantar tidak dapat dihuraikan menjadi jenis Perpuluhan',
    'account.withdraw.non_positive_amount': 'Nilai jumlah mestilah positif',
    'account.deposit.not_permitted': 'Deposit dibenarkan selepas pengesahan telefon',
    'account.withdraw.not_permitted': 'Sila lulus langkah pengesahan yang sesuai untuk mengeluarkan dana',
    'account.withdraw.insufficient_balance': 'Baki akaun tidak mencukupi',
    'account.withdraw.invalid_amount': 'Jumlah pengeluaran tidak sah',
    'account.withdraw.create_error': 'Gagal membuat pengeluaran',
    'account.withdraw.invalid_otp': 'Otp tidak sah',
    'account.withdraw.disabled_api': 'Pengeluaran API dilumpuhkan',
    'account.exchanges.exchange.success': 'Permintaan pertukaran telah diterima.',
    'account.exchange.non_positive_amount': 'Bidang pertukaran harus mempunyai nilai positif.',

    'market.market.doesnt_exist': 'Pasar tidak wujud',
    'market.order.invalid_state': 'Keadaan deposit tidak sah',
    'market.order.invalid_limit': 'Had tidak sah',
    'market.order.non_integer_limit': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'market.trade.empty_page': 'Halaman tiada atau kosong',
    'market.order.invalid_order_by': 'Pesanan_ tidak sah',
    'market.order.invalid_side': 'Bahagian pesanan tidak sah',
    'market.order.non_decimal_volume': 'Nilai kelantangan yang anda kirim tidak dapat diuraikan ke dalam jenis Perpuluhan',
    'market.order.non_positive_volume': 'Nilai isipadu mestilah positif',
    'market.order.invalid_type': 'Jenis pesanan tidak sah',
    'market.order.non_decimal_price': 'Nilai kelantangan yang anda kirim tidak dapat diuraikan ke dalam jenis Perpuluhan',
    'market.order.non_positive_price': 'Nilai isipadu mestilah positif',
    'market.order.non_integer_id': 'Nilai id yang anda hantar tidak dapat dihuraikan ke jenis Integer',
    'market.order.empty_id': 'Id tiada atau kosong',
    'market.trade.non_integer_limit': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'market.trade.invalid_limit': 'Had tidak sah',
    'market.trade.non_integer_timestamp': 'Nilai cap waktu yang anda hantar tidak dapat diuraikan ke jenis Integer',
    'market.trade.empty_timestamp': 'Cap waktu tiada atau kosong',
    'market.trade.invalid_order_by': 'Pesanan_ tidak sah',
    'market.order.insufficient_market_liquidity': 'Kecairan pasaran tidak mencukupi ',
    'market.order.invalid_volume_or_price': 'Jumlah atau harga tidak sah',
    'market.order.create_error': 'Gagal membuat ralat',
    'market.order.cancel_error': 'Gagal membatalkan ralat',
    'market.order.market_order_price': 'Pesanan pasaran tidak mempunyai harga',
    'market.trade.not_permitted': 'Sila lulus langkah pengesahan yang sesuai untuk membolehkan perdagangan',
    'market.account.insufficient_balance': 'Baki akaun tidak mencukupi',

    'public.currency.doesnt_exist': 'Mata wang tidak wujud',
    'public.currency.invalid_type': 'Jenis mata wang tidak sah',
    'public.market.doesnt_exist': 'Pasar tidak wujud',
    'public.order_book.non_integer_ask_limit': 'Nilai had tanya yang anda hantar tidak dapat diuraikan ke jenis Integer',
    'public.order_book.invalid_ask_limit': 'Had tanya tidak sah',
    'public.order_book.non_integer_bid_limit': 'Nilai had bid yang anda hantar tidak dapat diuraikan ke jenis Integer',
    'public.order_book.invalid_bid_limit': 'Had bidaan tidak sah',
    'public.trade.non_integer_limit': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'public.trade.invalid_limit': 'Had tidak sah',
    'public.trade.non_positive_page': 'Nilai halaman mesti positif',
    'public.trade.non_integer_timestamp': 'Nilai cap waktu yang anda hantar tidak dapat diuraikan ke jenis Integer',
    'public.trade.invalid_order_by': 'Pesanan tidak sah oleh',
    'public.market_depth.non_integer_limit': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'public.market_depth.invalid_limit': 'Had tidak sah',
    'public.k_line.non_integer_period': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'public.k_line.invalid_period': 'Tempoh tidak sah',
    'public.k_line.non_integer_time_from': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'public.k_line.empty_time_from': 'Time_from param tiada atau kosong',
    'public.k_line.non_integer_time_to': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'public.k_line.empty_time_to': 'Time_to param tiada atau kosong',
    'public.k_line.non_integer_limit': 'Nilai had yang anda kirim tidak dapat diuraikan ke jenis Integer',
    'public.k_line.invalid_limit': 'Had tidak sah',

    'server.internal_error': 'Ralat Pelayan Dalaman',
    'Server error': 'Ralat Pelayan Dalaman',

    'password.strength.tip.influence': 'Apa pengaruh pada kekuatan kata laluan',
    'password.strength.tip.number.characters': 'Sekurang-kurangnya 8 aksara',
    'password.strength.tip.letter': 'Sekurang-kurangnya Satu huruf besar dan huruf kecil',
    'password.strength.tip.digit': 'Sekurang-kurangnya Satu digit',
    ...nationalitiesNames,
};
