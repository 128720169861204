import { LangType } from '../../translations';
import { nationalitiesNames } from '../../translations/nationalities';

export const es: LangType = {
    //General Messages
    'no.record.found': 'Registro no encontrado.',
    'page.body.copy': 'Copiar',
    'page.body.copied': 'Copiada',
    'copied.to.clipboard': 'Copiada al portapapeles.',
    'learn.more': 'Aprende más',
    'need.to.enable.2fa.title': 'Debe habilitar 2FA antes de usar esta función.',
    'need.to.enable.2fa.button.title': 'Habilitar 2FA',
    'need.to.confirm.account': 'Debe confirmar su cuenta antes de usar esta función.',
    'need.to.confirm.account.button.title': 'Confirmar cuenta',
    'request.limit.exceeds': 'Se supera el límite de solicitudes.',

    'page.header.navbar.signIn': 'Sign In',
    'page.header.navbar.trade': 'Trade',
    'page.header.navbar.wallets': 'Cartera',
    'page.header.navbar.swap': 'Intercambiar',
    'page.header.navbar.buy_sell': 'Comprar/Vender',
    'page.header.navbar.openOrders': 'Órdenes',
    'page.header.navbar.history': 'Historial',

    'page.header.navbar.profile': 'Perfil',
    'page.header.navbar.logout': 'Salir',

    // Header Profile Links
    'page.header.navbar.profile.security': 'Seguridad',
    'page.header.navbar.profile.identification': 'Identificación',
    'page.header.navbar.profile.api_management': 'Gestión de API',
    'page.header.navbar.profile.referal': 'Referido',
    'page.header.navbar.profile.activities': 'Ocupaciones',

    //Header Notification Tab
    'page.header.navbar.notifications.title': 'Notificaciones',
    'page.header.navbar.notifications.empty.content1': "¡Estás al día!",
    'page.header.navbar.notifications.empty.content2': 'Visita a diario para noticias sobre criptos, alertas de precios y más',
    'page.header.navbar.notifications.detail.seen.button.text': 'entiendo',
    

    'page.sidebar.group.text': 'Grupo de Tarifas:',
    'page.sidebar.group.value': 'Base',

    'page.body.trade.header.market': 'Mercadot',
    'page.body.trade.header.yours': 'Tuyo',

    'page.body.trade.header.markets': 'Mercados',
    'page.body.trade.header.markets.content.market': 'Mercado',
    'page.body.trade.header.markets.content.pair': 'Par',
    'page.body.trade.header.markets.content.price': 'Precio',
    'page.body.trade.header.markets.content.last_price': 'Último precio',
    'page.body.trade.header.markets.content.change': 'Cambiar',
    'page.body.trade.header.markets.content.search': 'Búsqueda',
    'page.body.trade.header.markets.content.volume': 'Volumen',

    'page.body.trade.header.newOrder': 'Formulario de pedido',
    'page.body.trade.header.newOrder.content.tabs.buy': 'Comprar',
    'page.body.trade.header.newOrder.content.tabs.sell': 'Vender',
    'page.body.trade.header.newOrder.content.orderType': 'Tipo de orden',
    'page.body.trade.header.newOrder.content.orderType.limit': 'Límite',
    'page.body.trade.header.newOrder.content.orderType.market': 'Mercado',
    'page.body.trade.header.newOrder.content.price': 'Precio',
    'page.body.trade.header.newOrder.content.amount': 'Importe',
    'page.body.trade.header.newOrder.content.total': 'Total',
    'page.body.trade.header.newOrder.content.available': 'Disponible',

    'error.order.create.minAmount': 'La cantidad es muy baja. La cantida mínima es {amount} {currency}',
    'error.order.create.minPrice': 'El precio es muy bajo. El precio mínimo es {price} {currency}',
    'error.order.create.maxPrice': 'El precio es muy alto. el precio máximo es {price} {currency}',
    'error.order.create.available': 'Saldo excedido. Disponible {available} {currency}',

    'page.body.trade.header.marketDepths': 'Profundidad de mercado',
    'page.body.trade.header.marketDepths.content.price': 'Precio:',
    'page.body.trade.header.marketDepths.content.volume': 'Importe:',
    'page.body.trade.header.marketDepths.content.cumulativeVolume': 'Total:',
    'page.body.trade.header.marketDepths.content.cumulativeValue': 'Valor estimado:',

    'page.body.trade.toolBar.lowest': 'Más bajo 24h',
    'page.body.trade.toolBar.lastPrice': 'Último precio',
    'page.body.trade.toolBar.selectMarket': 'Selecciona Mercado',
    'page.body.trade.toolBar.highest': 'Más alto 24h',
    'page.body.trade.toolBar.volume': 'Volumen 24h',
    'page.body.trade.toolBar.change': 'Cambio',

    'page.body.trade.header.asks': 'Pedidos',
    'page.body.trade.header.bids': 'Pujas',
    'page.body.trade.orderbook': 'Libro de órdenes',
    'page.body.trade.orderbook.lastMarket': 'Último precio de mercado',
    'page.body.trade.orderbook.header.price': 'Precio',
    'page.body.trade.orderbook.header.amount': 'Importe',
    'page.body.trade.orderbook.header.volume': 'Total',

    'page.body.trade.header.recentTrades': 'Operaciones recientes',
    'page.body.trade.header.recentTrades.content.time': 'Hora',
    'page.body.trade.header.recentTrades.content.price': 'Precio',
    'page.body.trade.header.recentTrades.content.amount': 'Importe',

    'page.body.trade.header.openOrders': 'Órdenes abiertas',
    'page.body.trade.header.openOrders.content.date': 'Fecha',
    'page.body.trade.header.openOrders.content.state': 'Estado',
    'page.body.trade.header.openOrders.content.price': 'Precio',
    'page.body.trade.header.openOrders.content.amount': 'Importe',
    'page.body.trade.header.openOrders.content.total': 'Valor',
    'page.body.trade.header.openOrders.content.filled': 'Relleno',


    /* Markets Table */
    'page.body.marketsTable.filter.all': 'Todos',
    'page.body.marketsTable.header.pair': 'Mercado',
    'page.body.marketsTable.header.lastPrice': 'Último Precio',
    'page.body.marketsTable.header.change': 'Cambio 24h ',
    'page.body.marketsTable.header.high': 'Alto 24h',
    'page.body.marketsTable.header.low': 'Bajo 24h',
    'page.body.marketsTable.header.volume': 'Volumen 24h',


    /* Landing */
    'page.body.landing.header.button1': 'Perfil',
    'page.body.landing.header.button2': 'Acceder',
    'page.body.landing.header.button3': 'Registro',

    'page.body.landing.marketInfo.title.text1': 'Bienvenido a B4U Wallet and Exchange.',
    'page.body.landing.marketInfo.title.text2': 'Compra, vende y opera con moneda digital.',
    'page.body.landing.marketInfo.title.button': 'Comienza a operar',

    'page.body.landing.platformInfo.item.first.value': '30M+',
    'page.body.landing.platformInfo.item.first.title': 'Clientes atendidos',
    'page.body.landing.platformInfo.item.second.value': '$3M+',
    'page.body.landing.platformInfo.item.second.title': 'Volumen 30 días',
    'page.body.landing.platformInfo.item.third.value': '101',
    'page.body.landing.platformInfo.item.third.title': 'Países admitidos',

    'page.body.landing.register.item.title': 'No importa tu nivel de experiencial',
    'page.body.landing.register.item.text': 'B4U Wallet and Exchange te ofrece una interzfaz intuitiva con libros de pedidos en tiempo real, herramientas de gráficos, historial de operaciones y un proceso de pedido simple para que pueda operar desde el primer día.',
    'page.body.landing.register.item.button': 'Registro',

    'page.body.landing.features.title': 'Características de la plataforma B4U Wallet and Exchange',
    'page.body.landing.features.features.item1.title': 'Exchange',
    'page.body.landing.features.features.item2.title': 'Tipos de o´rdenes',
    'page.body.landing.features.features.item3.title': 'Interfaz personalizable',
    'page.body.landing.features.features.item4.title': 'Seguridad',
    'page.body.landing.features.features.item5.title': 'Comunidad',
    'page.body.landing.features.features.item6.title': 'API líder an la industria',
    'page.body.landing.features.features.item1.text': 'B4U Wallet and Exchange ofrece la cartera de pedidos más líquida del mundo, lo que permite a los usuarios intercambiar fácilmente Bitcoin, Ethereum, EOS, Litecoin, Ripple, NEO y muchos otros activos digitales de forma sencilla.',
    'page.body.landing.features.features.item2.text': 'B4U Wallet and Exchange ofrece un conjunto de tipos de órdenes para brindarles a los operadores las herramientas que necesitan para cada escenario. Descubre más sobre nuestros tipos de órdenes algorítmicas más avanzados.',
    'page.body.landing.features.features.item3.text': 'Organiza tu espacio de trabajo según sus necesidades: redacta tu diseño, elige entre temas, configura notificaciones y preferencias de datos.',
    'page.body.landing.features.features.item4.text': 'La seguridad de la información y los fondos del usuario es nuestra primera prioridad. Contáctanos para obtener más información sobre nuestras funciones e integraciones de seguridad.',
    'page.body.landing.features.features.item5.text': 'Únete a una comunidad global que cree en el poder de las criptomonedas.',
    'page.body.landing.features.features.item6.text': 'Nuestro Websocket feed te permite acceder fácilmente a datos de mercado en tiempo real, mientras que nuestra API comercial te permite desarrollar bots comerciales programáticos y seguros.',

    'page.body.landing.tradeOnTheGo.item.title': 'Opera sobre la marcha.',
    'page.body.landing.tradeOnTheGo.item.text1': 'Nuestra plataforma está optimizada para operar desde cualquier dispositivo.',
    'page.body.landing.tradeOnTheGo.item.text2': 'Y no necesitasa descargar ninguna aplicación adicional.',
    'page.body.landing.tradeOnTheGo.item.text3': 'Todo el poder del exchange de criptomonedas B4U Wallet en la palma de tu mano.',
    'page.body.landing.tradeOnTheGo.item.button': 'pruébalo',

    'page.body.landing.startTrading.title': 'Comenzar a operar ahora',
    'page.body.landing.startTrading.button1': 'registro',
    'page.body.landing.startTrading.button2': 'Operar',

    'page.body.landing.footer.exchange': 'Exchange',
    'page.body.landing.footer.wallets': 'Cartera',
    'page.body.landing.footer.fees': 'Tasas',
    'page.body.landing.footer.faq': 'FAQ',
    'page.body.landing.footer.support': 'Soporte',
    'page.body.landing.footer.privacy': 'Privacidad',
    'page.body.landing.footer.about': 'Sobre nosotros',
    'page.body.landing.footer.community': 'Comunidad',
    'page.body.landing.footer.info': 'Info',
    'page.body.landing.footer.rights': 'B4U Wallet & Exchange © Todos los derechos reservados.',


    /* Wallets */

    'page.body.wallets.title': 'Cartera',
    'page.body.wallets.action.deposit': 'Depositar',
    'page.body.wallets.action.withdraw': 'Retirar',
    'page.body.wallets.action.transfer': 'Transferir',
    'page.body.wallets.action.trade': 'Operar',
    'page.body.wallets.action.buy': 'Comprar',

    'page.body.wallets.estimated_value': 'Valor estimado',
    'page.body.wallets.input.search.placeholder': 'Búsqueda',
    'page.body.wallets.checkbox.label.hide_balance': 'Ocultar saldos pequeños',

    'page.body.wallets.table.header.coin': 'moneda',
    'page.body.wallets.table.header.total': 'Total',
    'page.body.wallets.table.header.available': 'Disponible',
    'page.body.wallets.table.header.locked': 'Bloqueado',
    'page.body.wallets.table.header.actions': 'Acciones',

    'page.body.wallets.locked': 'Bloqueado',
    'page.body.wallets.balance': 'Saldo',
    'page.body.wallets.tabs.deposit': 'Depósito',
    'page.body.wallets.tabs.deposit.disabled.message': 'El Depósito fue desactivado por administración',
    'page.body.wallets.tabs.deposit.ccy.message.submit': 'Envía un depósito utilizando la siguiente dirección o código QR. Tu depósito se verá reflejado en tu cuenta después de {confirmations} confirmaciones',
    'page.body.wallets.tabs.deposit.ccy.message.address': 'Dirrección del depósito',
    'page.body.wallets.tabs.deposit.ccy.message.button': 'COPIAR',
    'page.body.wallets.tabs.deposit.ccy.message.success': 'Dirección copiada',
    'page.body.wallets.tabs.deposit.ccy.message.error': 'Generando dirección del depósito',
    'page.body.wallets.tabs.deposit.ccy.button.generate': 'Generar',
    'page.body.wallets.tabs.deposit.ccy.button.address': 'dirección',

    'page.body.wallets.tabs.deposit.fiat.message1': 'Depositar usando transferencia bancaria',
    'page.body.wallets.tabs.deposit.fiat.message2': 'Utilice las siguientes credenciales para iniciar su transferencia bancaria. Su depósito se verá reflejado en su cuenta en un mínimo de 2 horas.',
    'page.body.wallets.tabs.deposit.fiat.message3': 'Utilice el siguiente código de referencia en su referencia de pago.',

    'page.body.wallets.tabs.deposit.fiat.bankName': 'Nombre del Banco',
    'page.body.wallets.tabs.deposit.fiat.accountNumber': 'Número de cuenta',
    'page.body.wallets.tabs.deposit.fiat.accountName': 'Titular de la cuenta',
    'page.body.wallets.tabs.deposit.fiat.phoneNumber': 'Número de teléfono',
    'page.body.wallets.tabs.deposit.fiat.referenceCode': 'Código de referencia',
    'page.body.wallets.table.pending': 'Pendiente',
    'page.body.wallets.table.rejected': 'Rechazado',
    'page.body.wallets.tabs.deposit.fiat.admin': ' ¡Para iniciar un retiro de FIAT, por favor contacta al administrador!',

    'page.body.wallets.tabs.withdraw': 'Retirar',
    'page.body.wallets.tabs.convert': 'Comprar y Vender',
    'page.body.wallets.tabs.withdraw.content.address': 'Dirección de retiro',
    'page.body.wallets.tabs.withdraw.content.amount': 'Importe del retiro',
    'page.body.wallets.tabs.withdraw.content.code2fa': 'Código 2FA',
    'page.body.wallets.tabs.withdraw.content.fee': 'Tasa',
    'page.body.wallets.tabs.withdraw.content.available': 'Monto disponible',
    'page.body.wallets.tabs.withdraw.content.total': 'Importe total del retiro',
    'page.body.wallets.tabs.withdraw.content.button': 'RETIRAR',
    'page.body.wallets.tabs.withdraw.disabled.message': 'Retiro fue desactivado por administración',
    'page.body.wallets.tabs.withdraw.amount.error': 'El monto del retiro no debe exceder el monto total del retiro. (El monto del retiro incluye una tarifa de retiro que es de {fee} {currency} por retiro)',

    'page.body.wallets.tabs.withdraw.modal.confirmation': 'Confirmación',
    'page.body.wallets.tabs.withdraw.modal.message1': 'Recibirás ',
    'page.body.wallets.tabs.withdraw.modal.message2': ' en la dirección',
    'page.body.wallets.tabs.withdraw.modal.button.cancel': 'Cancelar',
    'page.body.wallets.tabs.withdraw.modal.button.withdraw': 'Retirar',

    'page.body.wallets.tabs.withdraw.content.enable2fa': 'Para retirar debes habilitar 2FA',
    'page.body.wallets.tabs.withdraw.content.enable2faButton': 'Habilitar 2FA',

    'success.beneficiaries.created': 'Beneficiario: creado con éxito',
    'success.beneficiaries.activated': 'Beneficiario: activado con éxito',
    'success.beneficiaries.deleted': 'Beneficiario: eliminado con éxito',

    'page.body.wallets.beneficiaries.title': 'Dirección de retiro',
    'page.body.wallets.beneficiaries.fiat.title': 'Detalles del retiro',
    'page.body.wallets.beneficiaries.dropdown.address': 'Dirección',
    'page.body.wallets.beneficiaries.dropdown.select': 'Seleccionar',
    'page.body.wallets.beneficiaries.dropdown.name': 'Nombre',

    'page.body.wallets.beneficiaries.dropdown.fiat.account': 'Cuenta',
    'page.body.wallets.beneficiaries.dropdown.fiat.bankOfBeneficiary': 'Banco del beneficiario',
    'page.body.wallets.beneficiaries.dropdown.fiat.beneficiary': 'Beneficiario',
    'page.body.wallets.beneficiaries.dropdown.fiat.description': 'Descripción',
    'page.body.wallets.beneficiaries.dropdown.fiat.name': 'Nombre',
    'page.body.wallets.beneficiaries.dropdown.fiat.fullName': 'Nombre completo',

    'page.body.wallets.beneficiaries.addAddress': 'Añadir dirección',
    'page.body.wallets.beneficiaries.addAddressModal.header': 'Añadir nueva dirección de retiro',

    'page.body.wallets.beneficiaries.addAddressModal.body.coinAddress': 'Dirección de Blockchain',
    'page.body.wallets.beneficiaries.addAddressModal.body.coinBeneficiaryName': 'Nombre del beneficiario',
    'page.body.wallets.beneficiaries.addAddressModal.body.coinDescription': 'Descripción (opcional)',

    'page.body.wallets.beneficiaries.addAddressModal.body.fiatName': 'Descripción',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatFullName': 'Nombre completo',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatAccountNumber': 'Número de cuenta',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatBankName': 'Nombre del banco',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatBankSwiftCode': 'Código Swift (opcional)',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatIntermediaryBankName': 'Nombre del Banco intermediario (opcional)',
    'page.body.wallets.beneficiaries.addAddressModal.body.fiatIntermediaryBankSwiftCode': 'Código Swift del Banco intermediario (opcional)',


    'page.body.wallets.beneficiaries.addAddressModal.body.button': 'Enviar para confirmar',

    'page.body.wallets.beneficiaries.confirmationModal.header': 'Confirmar nueva dirección',
    'page.body.wallets.beneficiaries.confirmationModal.body.text': 'Te hemos enviado un email que contiene un código de confirmación (pin). Por favor introdúcelo debajo para guardar la nueva dirección:',
    'page.body.wallets.beneficiaries.confirmationModal.body.confirmationModalCode': 'Código PIN',
    'page.body.wallets.beneficiaries.confirmationModal.body.button': 'Confirmar',

    'page.body.wallets.beneficiaries.tipAddress': 'Dirección',
    'page.body.wallets.beneficiaries.tipName': 'Nombre',
    'page.body.wallets.beneficiaries.tipDescription': 'Nota',

    'page.body.wallets.beneficiaries.failAddModal.header': 'Advertencia',
    'page.body.wallets.beneficiaries.failAddModal.content': 'Necesitas confirmar tu cuenta para añadir un benficiario',
    'page.body.wallets.beneficiaries.failAddModal.button': 'Confirmar cuenta',

    'page.body.swap.title.swap': 'Intercambiar',
    'page.body.swap.title.buy_sell': 'Comprar/Vender',
    'page.body.swap.available': 'Disponible',
    'page.body.swap.input.swap': 'Intercambiar',
    'page.body.swap.input.sell': 'Vender',
    'page.body.swap.input.buy': 'Comprar',
    'page.body.swap.input.tag.max': 'Máximo',
    'page.body.swap.input.error1': 'El importe debe ser superior a {amount}.',
    'page.body.swap.input.error2': 'El importe debe ser inferiror a {amount}.',
    'page.body.swap.input.error3': 'Tu saldo es insuficiente.',
    'page.body.swap.input.otp_code': 'Código Otp',
    'page.body.swap.input.otp_code_error': 'Ingrese un código otp válido.',
    'page.body.swap.fee': 'Tasas',
    'page.body.swap.price': 'Precio',
    'page.body.swap.receive': 'Recibir',
    'page.body.swap.you_will_get': 'Conseguirás',
    'page.body.swap.button.text.swap': 'intercambiar',
    'page.body.swap.button.text.buy': 'Comprar',
    'page.body.swap.history.title.swap_history': 'Historial de intercambios',
    'page.body.swap.history.title.buy_sell_history': 'Historial Compra/Venta',
    'page.body.swap.history.table.column.sell': 'Vender',
    'page.body.swap.history.table.column.amount': 'Importe',
    'page.body.swap.history.table.column.buy': 'Comprar',
    'page.body.swap.history.table.column.status': 'Estado',
    'page.body.swap.history.table.column.date': 'Fecha',
    'page.body.swap.history.table.pagination.text.rows_per_page': 'Filas por página',

    'page.body.buy_crypto.title.buy_crypto': 'Comprar Crypto',
    'page.body.buy_crypto.content1': 'Compra Bitcoin y otras criptomonedas fácilmente',
    'page.body.buy_crypto.content2': 'Compra e invierte en minutos usando tu tarjeta de crédito, transferencia bancaria o Apple Pay.',
    'page.body.buy_crypto.fields.buy': 'Quiero comprar',
    'page.body.buy_crypto.fields.spend': 'Quiero gastar',
    'page.body.buy_crypto.fields.how_much': 'Por este importe',
    'page.body.buy_crypto.submit_button.text': 'Comprar {currencyCode}',
    'page.body.buy_crypto.modal.exit_button.text': 'Salir',
    'page.body.buy_crypto.field.error1': 'Importe insuficiente. El límite mínimo es {amount} {currencyCode}',
    'page.body.buy_crypto.field.error2': 'Límite excedido. El límite máximo por transacción es {amount} {currencyCode}',

    'page.body.openOrders.tab.all': 'Todo',
    'page.body.openOrders.tab.open': 'Abrir',
    'page.body.openOrders.header.orderType': 'Tipo de orden',
    'page.body.openOrders.header.orderType.buy.market': 'Compar a mercado',
    'page.body.openOrders.header.orderType.buy.limit': 'Comprar a límite',
    'page.body.openOrders.header.orderType.sell.market': 'Vender a mercado',
    'page.body.openOrders.header.orderType.sell.limit': 'Vender a límite',
    'page.body.openOrders.header.pair': 'Par',
    'page.body.openOrders.header.amount': 'Importe',
    'page.body.openOrders.header.price': 'Precio',
    'page.body.openOrders.header.executed': 'Ejecutado',
    'page.body.openOrders.header.remaining': 'Restante',
    'page.body.openOrders.header.costRemaining': 'Costo restante',
    'page.body.openOrders.header.status': 'Estado',
    'page.body.openOrders.content.status.done': 'Ejecutado',
    'page.body.openOrders.content.status.wait': 'Abierto',
    'page.body.openOrders.content.status.cancel': 'Detenido',
    'page.body.openOrders.header.button.cancelAll': 'Cancelar todo',

    'page.body.history.deposit': 'Historial de depósitos',
    'page.body.history.deposit.header.txid': 'txID',
    'page.body.history.deposit.header.date': 'Fecha',
    'page.body.history.deposit.header.currency': 'Moneda',
    'page.body.history.deposit.header.amount': 'Importe',
    'page.body.history.deposit.header.status': 'Estado',
    'page.body.history.deposit.content.status.accepted': 'Acceptado',
    'page.body.history.deposit.content.status.collected': 'Recogido',
    'page.body.history.deposit.content.status.submitted': 'Presentado',
    'page.body.history.deposit.content.status.canceled': 'Cancelado',
    'page.body.history.deposit.content.status.rejected': 'Rechazado',
    'page.body.history.deposit.content.status.skipped': 'Omitido',
    'page.body.history.deposit.content.status.succeed': 'Tener éxito',

    'page.body.history.withdraw': 'Historial de retiros',
    'page.body.history.withdraw.header.id': 'ID',
    'page.body.history.withdraw.header.date': 'Fecha',
    'page.body.history.withdraw.header.currency': 'Moneda',
    'page.body.history.withdraw.header.address': 'Dirección',
    'page.body.history.withdraw.header.amount': 'Importe',
    'page.body.history.withdraw.header.fee': 'Tasas',
    'page.body.history.withdraw.header.status': 'Estado',
    'page.body.history.withdraw.header.txid': 'txID',
    'page.body.history.withdraw.content.status.prepared': 'Preparado',
    'page.body.history.withdraw.content.status.submitted': 'Presentado',
    'page.body.history.withdraw.content.status.skipped': 'Omitido',
    'page.body.history.withdraw.content.status.canceled': 'Cancelado',
    'page.body.history.withdraw.content.status.accepted': 'Aceptado',
    'page.body.history.withdraw.content.status.suspected': 'Sospechado',
    'page.body.history.withdraw.content.status.rejected': 'Rechazado',
    'page.body.history.withdraw.content.status.processing': 'Procesamcdp',
    'page.body.history.withdraw.content.status.succeed': 'Exitoso',
    'page.body.history.withdraw.content.status.failed': 'Fallido',
    'page.body.history.withdraw.content.status.confirming': 'Confirmando',
    'page.body.history.withdraw.content.status.errored': 'Error',
    'page.body.history.withdraw.content.status.collected': 'Recogida',

    'page.body.history.trade': 'Hisorial de operaciones',
    'page.body.history.trade.header.id': 'ID',
    'page.body.history.trade.header.date': 'Fecha',
    'page.body.history.trade.header.side': 'Lado',
    'page.body.history.trade.content.side.buy': 'comprar',
    'page.body.history.trade.content.side.sell': 'Vender',
    'page.body.history.trade.header.market': 'Mercado',
    'page.body.history.trade.header.price': 'Precio',
    'page.body.history.trade.header.total': 'Total',
    'page.body.history.trade.header.amount': 'Importe',
    'page.body.history.trade.header.balance': 'Saldo',

    // Deposit Screen
    'page.body.deposit.header.title': 'Deposit',
    'page.body.deposit.tabs.crypto': 'Crypto',
    'page.body.deposit.tabs.fiat': 'Fiat',
    'page.body.deposit.select.title': 'Buscar Moneda',
    'page.body.deposit.total_balance': 'Saldo Total',
    'page.body.deposit.tips.title': 'Consejos',
    'page.body.deposit.tips.tip1': 'Si has depositado, presta atención a los mensajes de texto, las cartas del sitio y los correos electrónicos que te enviamos.',
    'page.body.deposit.tips.tip2': 'Hasta que se realicen {confirmations} confirmaciones, una cantidad equivalente de sus activos no estará disponible temporalmente para retiros.',
    'page.body.deposit.network.title': 'Red de depósito',
    'page.body.deposit.network.message': 'Selecciona el formato de dirección de depósito B4U correspondiente de acuerdo con el tipo de cadena pública de la billetera transferida. Ten en cuenta que algunas billeteras pueden admitir múltiples tipos de cadena pública de transferencia de tokens, como las billeteras de intercambio generalmente admiten depósitos de los tipos ERC20, OMNI y TRC20 de USDT. Asegúrese de que el tipo de red de cadena pública seleccionado en el momento de la transferencia sea el mismo que para los depósitos B4U.',
    'page.body.deposit.network.address.text': 'Dirección {currency}',
    'page.body.deposit.network.tag.text': 'etiqueta {currency}',
    'page.body.deposit.network.tag.instruction': 'Ingrese los datos de la etiqueta y la dirección, que son necesarios para depositar {currency} en su cuenta B4U con éxito.',
    'page.body.deposit.network.address.instructions.title': 'Envía solo {currency} a esta dirección de depósito..',
    'page.body.deposit.network.address.instructions.description': 'El envío de monedas o tokens que no sean {currency} a esta dirección puede resultar en la pérdida de tu depósito..',

    'page.body.withdraw.header.title': 'Retiro',
    'page.body.withdraw.tabs.crypto': 'Crypto',
    'page.body.withdraw.tabs.fiat': 'Fiat',
    'page.body.withdraw.select.title': 'Buscar moneda',
    'page.body.withdraw.total_balance': 'Saldo Total',
    'page.body.withdraw.tips.title': 'Consejos',
    'page.body.withdraw.tips.tip1': 'No retires directamente a una dirección de crowdfund o ICO, ya que no se acreditarán tokens de dichas ventas en tu cuenta.',
    'page.body.withdraw.tips.tip2': "Al realizar un retiro a la dirección de un usuario de B4U Wallet, la tarifa de manejo se devolverá a la cuenta actual de forma predeterminada.",

    'page.body.profile.header.account': 'Perfil',

    'page.body.profile.title': 'Gestión de perfiles',
    'page.body.profile.tabs.security': 'Perfil / Seguridad',
    'page.body.profile.tabs.identification': 'Identificación',
    'page.body.profile.tabs.referral': 'Remisión',
    'page.body.profile.tabs.api_management': 'Gestión de API',
    'page.body.profile.tabs.activity': 'Actividad',

    'page.body.profile.tabs.referral.title': 'Invitar a amigas y ganar cripto juntos',
    'page.body.profile.tabs.referral.description': 'GANA HASTA UN 40% DE COMISIÓN CADA VEZ QUE TUS AMIGOS HAGAN UN COMERCIO EN B4U.',
    'page.body.profile.tabs.referral.instruction.title': 'Invitar ahora',
    'page.body.profile.tabs.referral.instruction.description': 'Utilice su enlace exclusivo para invitar a sus amigos por mensaje o correo electrónico. Su código de invitación predeterminado también se puede compartir en la vida real o como captura de pantalla.',

    'page.body.profile.tabs.referral.id': 'ID de referencia',
    'page.body.profile.tabs.referral.link': 'Enlace de referencia',

    'page.body.profile.header.account.content.password': 'Contraseña',
    'page.body.profile.header.account.content.password.button.change': 'Cambiar',
    'page.body.profile.header.account.content.password.old': 'Contraseña anterior',
    'page.body.profile.header.account.content.password.new': 'Nueva contraseña',
    'page.body.profile.header.account.content.password.button.save': 'Guardar',
    'page.body.profile.header.account.content.password.button.cancel': 'Cancelñar',
    'page.body.profile.header.account.content.password.conf': 'Password Confirmación',
    'page.body.profile.header.account.content.password.dont.match': 'Las contraseñas no coinciden',
    'page.body.profile.header.account.content.password.change.success': '¡Éxito!',
    'page.body.profile.header.account.content.password.change': 'Cambiar contraseña',

    'page.body.profile.header.account.content.twoFactorAuthentication': '2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.enable': 'Habilitado',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.disable': 'Desabilitado',
    'page.body.profile.header.account.content.twoFactorAuthentication.header': 'Google Authenticator',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.1': 'Descarga e instala la aplicación Google Authenticator desde ',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.or': 'o ',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.2': 'Escanea el código QR o utiliza el código secreto MFA:',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.3': '* Guarda este código secreto en un lugar seguro. Este código se puede utilizar para obtener acceso 2FA desde un dispositivo diferente.',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.mfa': 'Código MFA',
    'page.body.profile.header.account.content.twoFactorAuthentication.message.4': 'Introduce el código 2FA de la aplicación',
    'page.body.profile.header.account.content.twoFactorAuthentication.subHeader': 'Código 2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.enable': 'HABILITAR',
    'page.body.profile.header.account.content.twoFactorAuthentication.disable': 'DESHaBILITAR 2FA',
    'page.body.profile.header.account.content.twoFactorAuthentication.modalBody': 'por favor, contacta con el adminsitrador para deshabilitar',
    'page.body.profile.header.account.content.twoFactorAuthentication.modalHeader': 'Factor 2FA de autentificación está habilitado',

    'page.body.profile.header.account.profile': 'Verificación del perfil',
    'page.body.profile.header.account.profile.email.title': 'Email verificado',
    'page.body.profile.header.account.profile.email.message': 'Depositar/Retirar habilitado',
    'page.body.profile.header.account.profile.phone.unverified.title': 'Verificación de teléfono',
    'page.body.profile.header.account.profile.phone.title': 'Teléfono verificado',
    'page.body.profile.header.account.profile.phone.message': 'Depositar/Operar habilitado',
    'page.body.profile.header.account.profile.identity.unverified.title': 'Verificación de identidad',
    'page.body.profile.header.account.profile.identity.title': 'Identidad verificada',
    'page.body.profile.header.account.profile.identity.message': 'Retirar habilitado',

    'page.body.profile.header.referralProgram': 'Enlace de referido',
    'page.body.profile.content.copyLink': 'Copiar',

    'page.body.profile.apiKeys.header': 'Mis API Keys',
    'page.body.profile.apiKeys.header.create': 'Crear nueva',

    'page.body.profile.apiKeys.noOtp': 'Por favor habilita autentificación 2FA',
    'page.body.profile.apiKeys.show': 'mostrar',
    'page.body.profile.apiKeys.noKeys': 'No tienes API keys',

    'page.body.profile.apiKeys.modal.btn.show': 'Mostrar',
    'page.body.profile.apiKeys.modal.btn.create': 'Confirmar',
    'page.body.profile.apiKeys.modal.btn.copy': 'Copiar',
    'page.body.profile.apiKeys.modal.btn.activate': 'Activar',
    'page.body.profile.apiKeys.modal.btn.disabled': 'Deshabilitar',
    'page.body.profile.apiKeys.modal.btn.delete': 'Borrar',
    'page.body.profile.apiKeys.modal.header': 'Verificación 2FA',
    'page.body.profile.apiKeys.modal.created_header': 'Creado',
    'page.body.profile.apiKeys.modal.access_key': 'Clave de acceso',
    'page.body.profile.apiKeys.modal.secret_key': 'Clave secreta',
    'page.body.profile.apiKeys.modal.secret_key_info': 'Esta información se mostrará sólo una vez y no puede recuperarse una vez perdida.',
    'page.body.profile.apiKeys.modal.secret_key_store': 'Por favor, almacénala adecuadamente.',
    'page.body.profile.apiKeys.modal.note': 'Nota',
    'page.body.profile.apiKeys.modal.note_content': `Para evitar la pérdida de activos, no reveles tu clave secreta y tu clave privada a otras personas.\
Si olvidas tu clave secreta, elimínala y solicita un nuevo par.`,
    'page.body.profile.apiKeys.modal.title': 'Introduce el código 2FA de la app',
    'page.body.profile.apiKeys.modal.label': 'Cçodigo de 6-dígitos de Google Authenticator',
    'page.body.profile.apiKeys.modal.placeholder': 'Introduce código',

    'page.body.profile.apiKeys.table.header.kid': 'Hijo',
    'page.body.profile.apiKeys.table.header.algorithm': 'Algoritmo',
    'page.body.profile.apiKeys.table.header.state': 'Estado',
    'page.body.profile.apiKeys.table.header.created': 'Creado',
    'page.body.profile.apiKeys.table.header.updated': 'Actualizado',

    'success.api_keys.fetched': 'Successfully fetched API keys',
    'success.api_keys.created': 'API key was created',
    'success.api_keys.copied.access': 'Access key was copied',
    'success.api_keys.copied.secret': 'Secret key was copied',
    'success.api_keys.updated': 'API key was updated',
    'success.api_keys.deleted': 'API key was deleted',

    'page.body.profile.header.accountActivity': 'Account Activity',
    'page.body.profile.header.accountActivity.content.date': 'Date',
    'page.body.profile.header.accountActivity.content.addressip': 'Address IP',
    'page.body.profile.header.accountActivity.content.action': 'Action',
    'page.body.profile.header.accountActivity.content.result': 'Result',
    'page.body.profile.header.accountActivity.content.userAgent': 'User Agent',

    'page.body.profile.content.action.login': 'Acceder',
    'page.body.profile.content.action.logout': 'Salir',
    'page.body.profile.content.action.request2fa': 'Solicitar Código QR para 2FA',
    'page.body.profile.content.action.enable2fa': 'Habilitar 2FA',
    'page.body.profile.content.action.login.2fa': 'Acceder con 2FA',
    'page.body.profile.content.action.requestPasswordReset': 'Solicitar reseteo de contraseña',
    'page.body.profile.content.action.passwordReset': 'Resetear contraseña',

    'page.body.profile.content.result.succeed': 'Éxito',
    'page.body.profile.content.result.failed': 'Fallido',
    'page.body.profile.content.result.denied': 'Denegado',

    'page.body.kyc.phone.head': 'Verificar teléfono',
    'page.body.kyc.phone.enterPhone': 'Introduce número de teléfono',
    'page.body.kyc.phone.phoneNumber': 'Número de teléfono',
    'page.body.kyc.phone.enterCode': 'Introduce código de confirmación',
    'page.body.kyc.phone.code': 'Código SMS',
    'page.body.kyc.phone.send': 'ENVIAR CÓDIGO',
    'page.body.kyc.phone.resend': 'REENVIAR CÓDIGO',
    'page.body.kyc.identity.firstName': 'Nombre',
    'page.body.kyc.identity.lastName': 'Apellidos',
    'page.body.kyc.identity.dateOfBirth': 'Fecha de nacimiento',
    'page.body.kyc.identity.residentialAddress': 'Dirección de residencia',
    'page.body.kyc.identity.city': 'Ciudad',
    'page.body.kyc.identity.postcode': 'Código postal',
    'page.body.kyc.identity.nationality': 'Nacionalidad',
    'page.body.kyc.identity.CoR': 'país de residencia',
    'page.body.kyc.documents.expiryDate': 'Fecha de expiración',
    'page.body.kyc.documents.drag': 'Arrastra y suelta o busca archivos',
    'page.body.kyc.documents.maxFile': 'Tamaño máximo de archivo = 10MB',
    'page.body.kyc.documents.maxNum': 'Máximo número de archivos = 5',
    'page.body.kyc.documents.upload': 'Sube tu foto',
    'page.body.kyc.documents.select.passport': 'Pasaporte',
    'page.body.kyc.documents.select.identityCard': 'Documento de identidad',
    'page.body.kyc.documents.select.driverLicense': 'Carnet de conducir',
    'page.body.kyc.documents.select.utilityBill': 'Factura de servicios',
    'page.body.kyc.documents.number': ': Número',
    'page.body.kyc.documentsType': 'Tipo de documento',

    'page.body.kyc.next': 'Siguiente',
    'page.body.kyc.submit': 'Enviar',
    'page.body.kyc.head.phone': 'Verificación de teléfono',
    'page.body.kyc.head.identity': 'Verificación de identidad',
    'page.body.kyc.head.document': 'Verificación de documento',

    'page.body.lock.oops': 'Oops!',
    'page.body.lock.expired': 'Parece que tu período de prueba ha expirado',
    'page.body.lock.license': 'Parece que tu licencia no es válida',
    'page.body.lock.visit': 'Visitar',

    'page.footer.legalDocuments': 'Documentos legales',
    'page.footer.faq': 'FAQ',

    'page.header.signIn': 'Acceder',
    'page.header.signIn.email': 'Email',
    'page.header.signIn.password': 'contraseña',
    'page.header.signIn.password.message.error': 'La contraseña no es válida',
    'page.header.signIn.receiveConfirmation': '¿No has recibido el correo de confirmación?',
    'page.header.signIn.forgotPassword': '¿Contraseña olvidada?',
    'page.header.signIn.resetPassword.title': 'Resetear contraseña',
    'page.header.signIn.resetPassword.newPassword': 'Nueva contraseña',
    'page.header.signIn.resetPassword.repeatPassword': 'Repetir contraseña',
    'page.header.signIn.resetPassword.button': 'Cambiar',
    'page.header.signIn.resetPassword.error': 'Los campos están vacíos o no coinciden',

    'page.header.signUp': 'Registro',
    'page.header.signUp.email': 'Email',
    'page.header.signUp.email.message.error': 'El email no es válido',
    'page.header.signUp.password': 'contraseña',
    'page.header.signUp.password.message.error': 'La contraseña debe contener al menos 8 caracteres, una letra mayúscula y un caracter numérico',
    'page.header.signUp.confirmPassword': 'Confirmar contraseña',
    'page.header.signUp.confirmPassword.message.error': 'Las contraseñas no coinciden',
    'page.header.signUp.referalCode': 'Código de referido',
    'page.header.signUp.terms': 'He leído y acepto los términos y condiciones del servicio',
    'page.header.signUp.modal.header': 'VERIFICA TU DIRECCIÓN DE CORREO ELECTRÓNICO',
    'page.header.signUp.modal.body': 'Para completar tu registro, comprueba ' +
                                      'el email en tu bandeja de entrada con ' +
                                      'instrucciones. Si pno puedes encontrar el email, ' +
                                      'por favor, comprueba tu bandeja de spam',
    'page.header.signUp.modal.footer': 'OK',
    'page.header.signUp.strength.password': 'Seguridad de la contraseña',
    'page.header.signUp.password.too.weak': 'MUY DÉBIL',
    'page.header.signUp.password.weak': 'DÉBIL',
    'page.header.signUp.password.good': 'BUENA',
    'page.header.signUp.password.strong': 'SEGURA',
    'page.header.signUp.password.very.strong': 'MUY SEGURA',
    'page.resendConfirmation': 'Reenviar confirmación',
    'page.forgotPassword': 'Contraseña olvidada',
    'page.forgotPassword.message': 'introduce email para resetear contraseña',
    'page.password2fa': 'Verificación 2FA',
    'page.password2fa.message': 'Introduce el código 2FA de la app',
    'page.forgotPassword.email': 'Email',
    'page.forgotPassword.send': 'Enviar',
    'page.noDataToShow': 'No hay datos para mostrar',

    'page.modal.withdraw.success': '¡Éxito!',
    'page.modal.withdraw.success.message.content': 'Tu solicitud de retiro ha sido recibida',
    'page.modal.withdraw.success.button': 'OK',

    'page.modal.expired.title': 'Tu sesión ha expirado',
    'page.modal.expired.submit': 'Accededr de nuevo',


    /* Customization */
    'page.body.customization.tabs.themes': 'Colores',
    'page.body.customization.tabs.fonts': 'Fuentes',
    'page.body.customization.tabs.spacing': 'Espaciado',
    'page.body.customization.tabs.images': 'Imágenes',
    'page.body.customization.comingSoon': 'Próximamente',
    'page.body.customization.actionButtons.reset': 'Resetear',
    'page.body.customization.actionButtons.save': 'Guardar',

    'page.body.customization.themes.selector.label': 'Preselecciones del tema',

    'page.body.customization.themes.color.mainBackgroundColor': 'Fondo principal',
    'page.body.customization.themes.color.bodyBackgroundColor': 'Fondo del cuerpo',
    'page.body.customization.themes.color.headerBackgroundColor': 'Fondo de la cabecera',
    'page.body.customization.themes.color.subheaderBackgroundColor': 'Fondo de la subcabecera',
    'page.body.customization.themes.color.dropdownBackgroundColor': 'Fondo del desplegable',
    'page.body.customization.themes.color.icon': 'Iconos',
    'page.body.customization.themes.color.primaryCtaColor': 'Llamada a la acción primaria',
    'page.body.customization.themes.color.contrastCtaColor': 'Contraste de llamada a la acción',
    'page.body.customization.themes.color.secondaryContrastCtaColor': 'Contrasete CTA secundario',
    'page.body.customization.themes.color.ctaLayerColor': 'Capa CTA',
    'page.body.customization.themes.color.systemGreen': 'Sistema Verde',
    'page.body.customization.themes.color.systemRed': 'Sistema Rojo',
    'page.body.customization.themes.color.systemYellow': 'Sistema Amarillo',
    'page.body.customization.themes.color.asks': 'Color de pedidos',
    'page.body.customization.themes.color.bids': 'Color de pujas',
    'page.body.customization.themes.color.primaryTextColor': 'Texto primario',
    'page.body.customization.themes.color.textContrastColor': 'Contraste del texto',
    'page.body.customization.themes.color.inputBackgroundColor': 'Fondo de entrada',
    'page.body.customization.themes.color.dividerColor': 'Color de divisor',
    'page.body.customization.themes.color.shadowColor': 'Color de sombra',
    'page.body.customization.themes.color.landingBackgroundColor': 'Fondo de aterrizaje',
    'page.body.customization.themes.color.strengthMeterVeryStrong': 'Contraseña muy segura',

    'page.body.customization.themes.theme.darkBlue.title': 'Azul oscuro',
    'page.body.customization.themes.theme.darkRed.title': 'Rojo oscuro',
    'page.body.customization.themes.theme.purple.title': 'Morado',
    'page.body.customization.themes.theme.green.title': 'Verde',


    // success messages
    'success.documents.accepted': 'Carga de documentos exitosa',
    'success.identity.accepted': 'Carga de identidad exitosa',
    'success.withdraw.action': 'La solicitud de retiro ha sido recibida',
    'success.otp.enabled': '2FA ha sido habilitado',
    'success.otp.disabled': '2FA ha sido deshabilitadp',
    'success.password.changed': 'La contraseña ha sido modificada',
    'success.password.forgot': 'El enlace para resetear la contraseña se ha enviado a tu email',
    'success.password.changed.successfuly': 'La contraséa cambió',
    'success.order.cancelling': 'La orden está siendo cancelada',
    'success.order.canceled': 'La orden ha sido cancelada',
    'success.order.canceled.all': 'Todas las órdenes han sido canceladas',
    'success.order.cancelling.all': 'Todas las órdenes están siendo canceladas',
    'success.phone.verification.send': 'El código de verificación se ha enviado a tu teléfono',
    'success.phone.confirmed': 'Tu teléfono ha sido confirmado',
    'success.phone.confirmation.message': '¡Éxito!',
    'success.message.sent': 'El mensaje ha sido enviado',
    'success.email.confirmed': 'Tu e-mail ha sido confirmado correctamente',
    'success.order.created': 'La orden ha sido creada',
    'success.order.done': 'La orden se completó correctamente',
    'success.data.changed.language': 'El idioma ha sido modificado',

    // error messages
    'error.order.rejected': 'Orden rechazada',
    'error.invalid_request': 'Orden - Solicitud inválida',
    'error.bad_request': 'Orden - Solicitud incorrecta',
    'error.request_entity_too_large': 'Orden - entidad de solicitud demasiado grande',

    // barong
    'resource.labels.private': 'No se puede actualizar etiqueta',
    'resource.user.no_activity': 'No hay actividad registrada o tema incorrecto',
    'resource.profile.not_exist': 'El usuario no tiene perfil',
    'resource.profile.exist': 'el perfil ya existe',
    'resource.api_key.2fa_disabled': 'Sólo se permiten cuentas con 2FA habilitado',
    'resource.api_key.missing_otp': 'La cuenta tiene habilitado 2FA pero falta el código OTP',
    'resource.api_key.invalid_otp': 'Código OTP no válido',
    'resource.phone.twillio': 'Algo anda mal con el cliente Twilio',
    'resource.phone.invalid_num': 'El número de teléfono no es válido',
    'resource.phone.exists': 'El número de teléfono no ha sido verificado. El código de verificación se ha vuelto a enviar.',
    'resource.phone.number_exist': 'El número de teléfono ya existe',
    'resource.phone.verification_invalid': 'Teléfono no encontrado o código no válido',
    'resource.documents.limit_reached': 'Se ha alcanzado el número máximo de documentos',
    'resource.documents.limit_will_be_reached': 'El número máximo de documentos se alcanzará con esta carga',
    'resource.otp.already_enabled':'2FA ya ha sido habilitado para este cuenta',
    'resource.otp.invalid': 'El código OTP no es válido',
    'resource.password.doesnt_match': 'Las nuevas contraseñas no coinciden',
    'resource.password.prev_pass_not_correct': 'La contraseña anterior no es correcta',
    'resource.password.no_change_provided': 'La nueva contraseña no puede ser la misma que la contraseña anterior',
    'resource.document.empty_doc_expire': 'Fecha de expiración no válida',
    'password.requirements': 'La contraseña no cumple los requisitos mínimos',
    'password.password.password_strength': 'La contraseña es muy débil',

    'email.taken': 'Email ya utilizado',

    'identity.user.invalid_referral_format': 'Formato de uid de referido no válido',
    'identity.user.referral_doesnt_exist': 'El referido no existe',
    'identity.user.active_or_doesnt_exist': 'El usuario no existe o ya ha sido activado',
    'identity.password.user_doesnt_exist': 'usuario no existeUser doesn\'t exist',
    'identity.user.passwords_doesnt_match': 'Las contraseñas no coincidenPasswords don\'t match',
    'identity.user.utilized_token': 'JWT ya está en uso',
    'identity.session.invalid_login_params': 'Email o contraseña incorrectos',
    'identity.session.invalid': 'Sesión no válida',
    'identity.captcha.required': 'captcha_response requerido',
    'identity.captcha.mandatory_fields': 'Los campos obligatorios deben completarse',
    'identity.session.not_active': 'Tu cuenta no está activa',
    'identity.session.banned': 'Tu cuenta ha sido baneada',
    'identity.session.invalid_params': 'Email o contraseña no válidos',
    'identity.session.missing_otp': 'La cuenta ha habilitado 2FA pero falta el código OTP',
    'identity.session.invalid_otp': 'Código OTP no válido',

    'first_name.invalid': 'Nombre no válido',
    'last_name.invalid': 'Apellido no válido',
    'city.invalid': 'Ciudad no válida',
    'postcode.invalid': 'Código postal no válido',
    'address.invalid': 'Dirección no válida',
    'first_name.blank': 'Falta nombre o está vacío',
    'last_name.blank': 'Falta apellido o está vacío',
    'dob.blank': 'Fecha de nacimiento no válida',
    'address.blank': 'Dirección falta o está vacía',
    'city.blank': 'Ciudad falta o está vacía',
    'country.blank': 'País falta o está vacío',
    'postcode.blank': 'Postcode.blank falata o está vacío',
    'country.must have alpha2 or alpha3 format': 'País debe tener formato alpha2 o alpha3',

    'totp.error': 'Código OTP no válido',

    'record.not_found': 'Registro no encontrado',
    'jwt.decode_and_verify': 'Error al decodificar y verificar JWT',
    'authz.invalid_session': 'Error al decodificar cookies',
    'authz.user_not_active': 'Usuario no activo',
    'authz.invalid_signature': 'API Key cabecera \'firma\' no válido',
    'authz.apikey_not_active': 'El estado de API Key es \'inactive',
    'authz.disabled_2fa': 'El propietario de la API Key ha deshabilitado 2FA',
    'authz.invalid_api_key_headers': 'Encabezados de API key en blanco o faltantes',
    'authz.permission_denied': 'La ruta está en la lista negra',
    'authz.unexistent_apikey': 'Cabecera X-Auth-Apikey no válida',
    'authz.client_session_mismatch': 'Discordancia de sesión',
    'authz.csrf_token_mismatch': 'Discordancia CSRF token',

    // validation errors
    // identity module
    'identity.user.missing_email': 'Falta Email',
    'identity.user.empty_email': 'Falta Email o está vacío',
    'identity.user.missing_password': 'Falta contraseña',
    'identity.user.empty_password': 'Falta contraseña o está vacía',
    'identity.user.missing_token': 'Falta Token',
    'identity.user.empty_token': 'Falta Token o está vacío',
    'identity.user.missing_reset_password_token': 'Falta token de reseteo de contraseña',
    'identity.user.empty_reset_password_token': 'Falta token de reseteo de contraseña o está vacío',
    'identity.user.missing_confirm_password': 'Falta Confirmación de contraseña',
    'identity.user.empty_confirm_password': 'Falta Confirmación de contraseña o está vacía',

    'identity.session.missing_emai': 'Falta Email',
    'identity.session.missing_password': 'Falta contraseña',
    'identity.session.invalid_captcha_format': 'Formato de captcha no válido',

    // resource module
    'resource.otp.missing_code': 'Falta código OTP',
    'resource.otp.empty_code': 'Falta código OTP o está vacío',

    'resource.labels.missing_key': 'Falta clave',
    'resource.labels.empty_key': 'Falta clave o está vacía',
    'resource.labels.missing_value': 'Falta valor',
    'resource.labels.empty_value': 'Falta valor o está vacío',

    'resource.documents.missing_doc_expire': 'Falta fecha de expiración de documentos',
    'resource.documents.empty_doc_expire': 'Falta fecha de expiración de documentos o está vacía',
    'resource.documents.missing_doc_type': 'Falta tipo de documentos',
    'resource.documents.empty_doc_type': 'Falta tipo de documentos o está vacío ',
    'resource.documents.missing_doc_number': 'Falta el número de coumentos',
    'resource.documents.empty_doc_number': 'Falta número de documento o está vacío',
    'resource.documents.missing_upload': 'Falta archivo adjunto',


    'resource.user.missing_topic': 'Falta tema',
    'resource.user.empty_topic': 'Falta tema o está vacío',
    'resource.user.missing_old_password': 'Falta antigua contraseña',
    'resource.user.empty_old_password': 'Falta antigua contraseña o está vacía',
    'resource.user.missing_new_password': 'Falta nueva contraseña',
    'resource.user.empty_new_password': 'Falta nueva contraseña o está vacía',
    'resource.user.missing_confirm_password': 'Falta confirmación de contraseña',
    'resource.user.empty_confirm_password': 'Falta confirmación de contraseña o está vacía',

    'resource.profile.missing_first_name': 'Falta nombre',
    'resource.profile.missing_last_name': 'Falta apellido',
    'resource.profile.missing_dob': 'Falta fecha de nacimeinto',
    'resource.profile.missing_address': 'Falta dirección',
    'resource.profile.missing_postcode': 'Falta código postal',
    'resource.profile.missing_city': 'Falta ciudad',
    'resource.profile.missing_country': 'Falta país',

    'resource.api_key.missing_algorithm': 'Falta algoritmo',
    'resource.api_key.empty_algorithm': 'Falta algortimo o está vacío',
    'resource.api_key.empty_kid': 'Falta KID o está vacío',
    'resource.api_key.empty_scope': 'Falta alcance o está vacío',
    'resource.api_key.missing_totp': 'Falta código TOTP',
    'resource.api_key.empty_totp': 'Falta código TOTP o está vacío',
    'resource.api_key.missing_kid': 'Falta KID ',
    'resource.api_key.empty_state': 'Falta estado o está vacíoy',

    'resource.phone.missing_phone_number': 'Falta número de teléfono',
    'resource.phone.empty_phone_number': 'Falta número de teléfono o está vacío',
    'resource.phone.missing_verification_code': 'Falta código de verificación',
    'resource.phone.empty_verification_code': 'Código de verificación falta o está vacío',

    // peatio
    'account.currency.doesnt_exist': 'La moneda no existe',
    'account.deposit.invalid_state': 'Estado de depósito no válido',
    'account.deposit.non_integer_limit': 'El valor enviado no se pudo analizar en el tipo Integer',
    'account.deposit.invalid_limit': 'Límite no válido',
    'account.deposit.non_positive_page': 'Valor de página debe ser positivo',
    'account.deposit.empty_txid': 'Txid falta o está vacío',
    'account.deposit_address.invalid_address_format': 'Formato de dirección de depósito no válido',
    'account.deposit_address.doesnt_support_cash_address_format': 'La moneda no soporta formato de dirección de efectivo',
    'account.withdraw.non_integer_limit': 'Valor límite enviado no se pudo analizar en el tipo Integer',
    'account.withdraw.invalid_limit': 'Límite no válido',
    'account.withdraw.non_positive_page': 'Valor de página debe ser positivo',
    'account.withdraw.non_integer_otp': 'Valor OTP no se pudo analizar en el tipo Integer',
    'account.withdraw.empty_otp': 'OTP falta, OTP está vacío',
    'account.withdraw.empty_rid': 'Rid falta, Rid está vacio',
    'account.withdraw.non_decimal_amount': 'El importe que has enviado no se pudo analizar en tipo Decimal',
    'account.withdraw.non_positive_amount': 'El importe debe ser positivoe',
    'account.deposit.not_permitted': 'Los depósitos estásn permitidos tras la verificación de teléfono',
    'account.withdraw.not_permitted': 'Por favor,  pasa los correspondientes pasos de verificación para retirar fondos',
    'account.withdraw.insufficient_balance': 'El saldo de la cuent aes insuficiente',
    'account.withdraw.invalid_amount': 'Importe de reterio no válido',
    'account.withdraw.create_error': 'Fallo al crear retiro',
    'account.withdraw.invalid_otp': 'OTP no válido',
    'account.withdraw.disabled_api': 'API de retiros está deshabilitada ',
    'account.exchanges.exchange.success': 'La solicitud de intercambio ha sido recibida.',
    'account.exchange.non_positive_amount': 'El campo interambio debe tener valor positivo.',

    'market.market.doesnt_exist': 'El mercado no existe',
    'market.order.invalid_state': 'Estado de depósito no válido',
    'market.order.invalid_limit': 'Límite no válido',
    'market.order.non_integer_limit': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'market.trade.empty_page': 'La página falta o está vacía',
    'market.order.invalid_order_by': 'Order_by no válido',
    'market.order.invalid_side': 'Lado de orden no válido',
    'market.order.non_decimal_volume': 'el valor de volumen que envías no se pudo analizar en el tipo Decimal',
    'market.order.non_positive_volume': 'El valor de volumen debe ser positivo',
    'market.order.invalid_type': 'Tipo de orden no válido',
    'market.order.non_decimal_price': 'El valor de volumen que envías no se pudo analizar en el tipo Integer',
    'market.order.non_positive_price': 'El valor de volumen debe ser positivo',
    'market.order.non_integer_id': 'Id value  que envías no se pudo analizar en el tipo Integer',
    'market.order.empty_id': 'ID falta o está vacío',
    'market.trade.non_integer_limit': 'El valor límite  que envías no se pudo analizar en el tipo Integer',
    'market.trade.invalid_limit': 'Límite no válidot',
    'market.trade.non_integer_timestamp': 'El valor de marca de tiempo que envías no se pudo analizar en el tipo Integer',
    'market.trade.empty_timestamp': 'Marca de tiempo falta o está vacía',
    'market.trade.invalid_order_by': 'order_by no válido',
    'market.order.insufficient_market_liquidity': 'Liquidez de mercado insuficiente ',
    'market.order.invalid_volume_or_price': 'Volumen o precio no válidos',
    'market.order.create_error': 'No se pudo crear el error',
    'market.order.cancel_error': 'No se pudo cancelar el error',
    'market.order.market_order_price': 'Orden de mercado no tiene precio',
    'market.trade.not_permitted': 'Por favor, pasa los pasos de verificación correspondientes para habilitar operar.',
    'market.account.insufficient_balance': 'El saldo de la cuenta es insuficiente',

    'public.currency.doesnt_exist': 'La moneda no existe',
    'public.currency.invalid_type': 'Tipo de moneda no válido',
    'public.market.doesnt_exist': 'Market doesn\'t exist',
    'public.order_book.non_integer_ask_limit': 'El valor límite de pedido que envías no se pudo analizar en el tipo Integer',
    'public.order_book.invalid_ask_limit': 'Invlalid ask limit',
    'public.order_book.non_integer_bid_limit': 'El valor límite de puja que envías no se pudo analizar en el tipo Integer',
    'public.order_book.invalid_bid_limit': 'Límite de puja no válido',
    'public.trade.non_integer_limit': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'public.trade.invalid_limit': 'Límite no válido',
    'public.trade.non_positive_page': 'Valor de página debe ser positivo',
    'public.trade.non_integer_timestamp': 'Timestamp value you send could not be parsed into Integer type',
    'public.trade.invalid_order_by': 'Orden no válida por',
    'public.market_depth.non_integer_limit': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'public.market_depth.invalid_limit': 'límite no válido',
    'public.k_line.non_integer_period': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'public.k_line.invalid_period': 'Período no válido',
    'public.k_line.non_integer_time_from': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'public.k_line.empty_time_from': 'El parámetro Time_from falta o está vacío',
    'public.k_line.non_integer_time_to': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'public.k_line.empty_time_to': 'El parámetro Time_to falta o no es válido',
    'public.k_line.non_integer_limit': 'El valor límite que envías no se pudo analizar en el tipo Integer',
    'public.k_line.invalid_limit': 'Límite no válido',

    'server.internal_error': 'Error Interno de Servidor',
    'Server error': 'Error Interno de Servidor',

    'password.strength.tip.influence': 'Influencia en la seguriadad de la contraseña',
    'password.strength.tip.number.characters': 'al menos 8 caracteres',
    'password.strength.tip.letter': 'Al menos una letra mayúscula y una minúscula',
    'password.strength.tip.digit': 'Al menos un dígito',

    ...nationalitiesNames,
};
