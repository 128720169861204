export const WALLETS_FETCH = 'wallets/FETCH';
export const WALLETS_DATA = 'wallets/DATA';
export const WALLETS_DATA_WS = 'wallets/DATA_WS';
export const WALLETS_ERROR = 'wallets/ERROR';
export const WALLETS_RESET = 'wallets/RESET';

export const SAVINGS_WALLETS_FETCH = 'savings_wallets/FETCH';
export const SAVINGS_WALLETS_DATA = 'savings_wallets/DATA';
export const SAVINGS_WALLETS_DATA_WS = 'savings_wallets/DATA_WS';
export const SAVINGS_WALLETS_ERROR = 'savings_wallets/ERROR';

export const P2P_WALLETS_FETCH = 'p2p_wallets/FETCH';
export const P2P_WALLETS_DATA = 'p2p_wallets/DATA';
export const P2P_WALLETS_DATA_WS = 'p2p_wallets/DATA_WS';
export const P2P_WALLETS_ERROR = 'p2p_wallets/ERROR';

export const WALLETS_ADDRESS_FETCH = 'wallets/ADDRESS_FETCH';
export const WALLETS_ADDRESS_DATA = 'wallets/ADDRESS_DATA';
export const WALLETS_ADDRESS_ERROR = 'wallets/ADDRESS_ERROR';

export const WALLETS_WITHDRAW_FIAT_FETCH = 'wallets/WITHDRAW_FIAT_FETCH';
export const WALLETS_WITHDRAW_FIAT_DATA = 'wallets/WITHDRAW_FIAT_DATA';
export const WALLETS_WITHDRAW_FIAT_ERROR = 'wallets/WITHDRAW_FIAT_ERROR';

export const WALLETS_WITHDRAW_CCY_FETCH = 'wallets/WITHDRAW_CCY_FETCH';
export const WALLETS_WITHDRAW_CCY_DATA = 'wallets/WITHDRAW_CCY_DATA';
export const WALLETS_WITHDRAW_CCY_ERROR = 'wallets/WITHDRAW_CCY_ERROR';

// export const MASS_WITHDRAW_PROCESSING = 'wallets/MASS_WITHDRAW_PROCESSING';
// export const MASS_WITHDRAW_SUCCESS = 'wallets/MASS_WITHDRAW_SUCCESS';
// export const MASS_WITHDRAW_ERROR = 'wallets/MASS_WITHDRAW_ERROR';

export const SET_MOBILE_WALLET_UI = 'wallets/SET_MOBILE_WALLET_UI';
