import * as React from 'react';

export const SortAsc = () => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M1.20711 6.20711C0.761654 5.76165 1.07714 5 1.70711 5H5.29289C5.92286 5 6.23835 5.76165 5.79289 6.20711L4 8C3.72386 8.27614 3.27614 8.27614 3 8L1.20711 6.20711Z" fill="white"/>
            <path opacity="1" fillRule="evenodd" clipRule="evenodd" d="M5.79289 2.79289C6.23835 3.23835 5.92286 4 5.29289 4L1.70711 4C1.07714 4 0.761654 3.23835 1.20711 2.79289L3 0.999999C3.27614 0.723857 3.72386 0.723858 4 1L5.79289 2.79289Z" fill="white"/>
        </svg>
    );
};

export const SortDefault = () => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M1.20711 6.20711C0.761654 5.76165 1.07714 5 1.70711 5H5.29289C5.92286 5 6.23835 5.76165 5.79289 6.20711L4 8C3.72386 8.27614 3.27614 8.27614 3 8L1.20711 6.20711Z" fill="white"/>
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M5.79289 2.79289C6.23835 3.23835 5.92286 4 5.29289 4L1.70711 4C1.07714 4 0.761654 3.23835 1.20711 2.79289L3 0.999999C3.27614 0.723857 3.72386 0.723858 4 1L5.79289 2.79289Z" fill="white"/>
        </svg>
    );
};

export const SortDesc = () => {
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="1" fillRule="evenodd" clipRule="evenodd" d="M1.20711 6.20711C0.761654 5.76165 1.07714 5 1.70711 5H5.29289C5.92286 5 6.23835 5.76165 5.79289 6.20711L4 8C3.72386 8.27614 3.27614 8.27614 3 8L1.20711 6.20711Z" fill="white"/>
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M5.79289 2.79289C6.23835 3.23835 5.92286 4 5.29289 4L1.70711 4C1.07714 4 0.761654 3.23835 1.20711 2.79289L3 0.999999C3.27614 0.723857 3.72386 0.723858 4 1L5.79289 2.79289Z" fill="white"/>
        </svg>
    );
};
