import * as React from 'react';

interface Props {
    className?: string;
}

export const RejectedIcon: React.FC<Props> = (props: Props) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6757 0.175736C10.9101 -0.0585787 11.2899 -0.0585787 11.5243 0.175736C11.7586 0.410051 11.7586 0.78995 11.5243 1.02426L6.69853 5.85L11.5243 10.6757C11.7586 10.9101 11.7586 11.2899 11.5243 11.5243C11.2899 11.7586 10.9101 11.7586 10.6757 11.5243L5.85 6.69853L1.02426 11.5243C0.789949 11.7586 0.410051 11.7586 0.175736 11.5243C-0.0585787 11.2899 -0.0585787 10.9101 0.175736 10.6757L5.00147 5.85L0.175736 1.02426C-0.0585786 0.78995 -0.0585786 0.410051 0.175736 0.175736C0.410051 -0.0585787 0.78995 -0.0585787 1.02426 0.175736L5.85 5.00147L10.6757 0.175736Z" fill="var(--system-red)"/>
    </svg>
);
