import * as React from 'react';

interface PlusIconProps {
    className?: string;
}

export const PlusIcon: React.FC<PlusIconProps> = (props: PlusIconProps) => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.25 1C6.25 0.585786 5.91421 0.25 5.5 0.25C5.08579 0.25 4.75 0.585786 4.75 1H6.25ZM4.75 10C4.75 10.4142 5.08579 10.75 5.5 10.75C5.91421 10.75 6.25 10.4142 6.25 10H4.75ZM10 6.25C10.4142 6.25 10.75 5.91421 10.75 5.5C10.75 5.08579 10.4142 4.75 10 4.75V6.25ZM1 4.75C0.585787 4.75 0.25 5.08579 0.25 5.5C0.25 5.91421 0.585786 6.25 1 6.25L1 4.75ZM4.75 1V5.5H6.25V1H4.75ZM4.75 5.5V10H6.25V5.5H4.75ZM10 4.75H5.5V6.25H10V4.75ZM5.5 4.75L1 4.75L1 6.25L5.5 6.25V4.75Z" fill="var(--icons)"/>
        </svg>
    );
};
