export const PROFILE_CHANGE_PASSWORD_FETCH = 'profile/CHANGE_PASSWORD_FETCH';
export const PROFILE_CHANGE_PASSWORD_DATA = 'profile/CHANGE_PASSWORD_DATA';
export const PROFILE_CHANGE_PASSWORD_ERROR = 'profile/CHANGE_PASSWORD_ERROR';

export const PROFILE_TOGGLE_2FA_FETCH = 'profile/TOGGLE_2FA_FETCH';
export const PROFILE_TOGGLE_2FA_DATA = 'profile/TOGGLE_2FA_DATA';
export const PROFILE_TOGGLE_2FA_ERROR = 'profile/TOGGLE_2FA_ERROR';

export const PROFILE_GENERATE_2FA_QRCODE_FETCH = 'profile/GENERATE_2FA_QRCODE_FETCH';
export const PROFILE_GENERATE_2FA_QRCODE_DATA = 'profile/GENERATE_2FA_QRCODE_DATA';
export const PROFILE_GENERATE_2FA_QRCODE_ERROR = 'profile/GENERATE_2FA_QRCODE_ERROR';

export const PROFILE_USER_FETCH = 'profile/GET_USER_FETCH';
export const PROFILE_USER_DATA = 'profile/GET_USER_DATA';
export const PROFILE_USER_ERROR = 'profile/GET_USER_ERROR';
export const PROFILE_USER_ACTIVITY = 'profile/GET_USER_ACTIVITY';
export const PROFILE_RESET_USER = 'profile/RESET_USER';

export const PROFILE_CHANGE_USER_LEVEL = 'profile/CHANGE_USER_LEVEL';

export const PROFILE_TOGGLE_USER_2FA = 'profile/TOGGLE_USER_2FA';

export const PROFILE_CHANGE_USER_FETCH = 'profile/CHANGE_USER_FETCH';
export const PROFILE_CHANGE_USER_DATA = 'profile/CHANGE_USER_DATA';
export const PROFILE_CHANGE_USER_ERROR = 'profile/CHANGE_USER_ERROR';

